'use strict';

(function () {

  var mod = angular.module('kerp-forms.forms');

  mod.directive('memorableDate', ['$moment', function ($moment) {

    return {
      restrict: 'EA',
      require: 'ngModel',
      scope: {
        form: "="
      },
      templateUrl: 'modules/forms/scripts/directives/memorableDate/memorableDateDirective.html',
      link: function (scope, element, attrs, ngModelController) {

        scope.dayPlaceHolder = 'DD';
        scope.monthPlaceHolder = 'MM';
        scope.yearPlaceHolder = 'YYYY';
        scope.isValidYear = true;
        scope.isValidMonth = true;
        scope.isValidDay = true;
        scope.inputFormat = scope.form.format || 'number';

        var FORMAT = scope.form.dateFormat || 'DD/MM/YYYY';

        // We need the ngModelController on several places, most notably for errors.
        // Usually it's the schema-validate directive that does this but since we're not using it we need to do it.
        scope.$emit('schemaFormPropagateNgModelController', ngModelController);

        /**
         * Construct a $moment instance
         * @returns {moment}
         */
        function createMomentFromInput() {
          if (!scope.year || scope.year.length < 4) {
            return;
          }

          if (scope.year && scope.month && scope.day) {
            var dateString = [scope.year, scope.month, scope.day].join('/');
            var dateMoment = $moment(dateString, 'YYYY/MM/DD');

            if (dateMoment.isValid()) {
              return dateMoment;
            }
          }
        }

        function isOptional() {
          return !scope.form.required;
        }

        function isBlank() {
          return !scope.year && !scope.month && !scope.day;
        }

        function isValidDateParts() {

          if (isOptional() && isBlank()) {
            return true;
          }
          var moment = createMomentFromInput();

          if (!moment) {
            return false;
          }

          var form = scope.form;
          var defaultMinDate = new Date();
          defaultMinDate.setFullYear(1900);
          var defaultMaxDate = new Date();
          var minDate = form.minDate || defaultMinDate;
          var maxDate = form.maxDate || defaultMaxDate;

          // min/max are inclusive
          return !(moment.isBefore(minDate, 'day') || moment.isAfter(maxDate, 'day'));
        }

        function validateInputDate() {
          var partsValid = isValidDateParts();

          ngModelController.$setValidity("date", partsValid);
          var moment = createMomentFromInput();

          if (partsValid && !isBlank() && moment) {
            var viewValue = moment.format(FORMAT);
            ngModelController.$setViewValue(viewValue);
          } else {
            ngModelController.$setViewValue(undefined);
          }
        }

        function isEmptyAndOptional() {
          var isValid = isBlank() && isOptional();

          if (isValid) {
            scope.isValidDay = true;
            scope.isValidMonth = true;
            scope.isValidYear = true;
          }
          return isValid;
        }

        scope.onDayChanged = function () {

          if (!isEmptyAndOptional()) {
            scope.isValidDay = !!scope.day;
          }
        };

        scope.onMonthChanged = function () {

          if (!isEmptyAndOptional()) {
            scope.isValidMonth = !!scope.month;
          }
        };

        scope.onYearChanged = function () {
          if (!isEmptyAndOptional()) {
            scope.isValidYear = !!scope.year;
          }
        };

        /*
         * When the model is updated, we spread the changes to the different scope variables (year, month, day)
         */
        scope.$watch(function () {
          return ngModelController.$modelValue;
        }, function (newValue, oldValue) {
          if (angular.isString(newValue) && FORMAT) {
            var moment = $moment(newValue, FORMAT);
            if (!moment) {
              console.error('Date value is not compatible with the current date format specified', newValue, FORMAT);
              ngModelController.$setViewValue(undefined);
              return;
            }

            var parts = moment.format('YYYY-MM-DD').split('-');

            scope.day = +parts[2];
            scope.month = +parts[1];
            scope.year = +parts[0];
          }
        });

        scope.hasSuccess = function () {
          return ngModelController.$valid && !ngModelController.$pristine;
        };

        scope.hasError = function () {
          return ngModelController.$invalid;
        };

        scope.$watchGroup(['day', 'month', 'year'], function (newValues, oldValue) {
          var allUndefined = !newValues || !newValues.filter(function (val) {
            return angular.isDefined(val);
          }).length;

          if (!allUndefined) {
            validateInputDate();
          }
        });

        scope.$on('schemaFormValidate', function () {
          validateInputDate();
        });
      }
    };
  }]);
}());
