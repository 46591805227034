'use strict';

angular.module('kerp-forms.forms')
  .controller(
    'FormListCtrl',
    ['$rootScope', '$scope', '$state', '$filter', '$localStorage', 'Forms', 'API', 'authSessionService', 'formModelService', 'offlineFormService', 'configuration', '$templateCache',
     function ($rootScope, $scope, $state, $filter, $localStorage, Forms, API, authSessionService, formModelService, offlineFormService, configuration, $templateCache) {

       $scope.customer = configuration.customer;
       $scope.notice = $templateCache.get('modules/forms/views/list/' + $scope.customer + '/notice.html');

       $scope.forms = Forms.getForms();

        $scope.$watch(offlineFormService.countForms, function (result) {
          $scope.offlineFormsCount = result;
        });

        // KERP-2186 if the user navigates to this page anonymously and then logs in, we need to reload the state
        // to get their applications
        $scope.$on('event:auth:login-success', function (event, session) {
          $state.reload();
        });

        // divide the forms into applications, appeals, and assessments
        $scope.appeals = [];
        $scope.assessments = [];
        var applications = [];

        $scope.forms.forEach(function (form) {

          switch (form.type) {
            case 'application':
              applications.push(form);
              break;

            case 'appeal':
              $scope.appeals.push(form);
              break;

            case 'assessment':
              $scope.assessments.push(form);
              break;

            default:
              throw new Error("Form '" + form.id + "' has an unrecognised type: " + form.type);
          }
        });

        $scope.canInvokeApi = function () {
          return $rootScope.app && $rootScope.app.hasSession() && !$rootScope.app.isOffline;
        };

        if (!$scope.canInvokeApi()) {
          // if a user is not logged in or app is offline they should still be able to see the list of forms
          $scope.applications = applications;
          return;
        }

        API.get('/entitlements').then(function (response) {
          var userEntitlements = response.data || [];
          function getEntitledForms(isDefinite) {
            return applications.filter(function (form) {
              if (form.hasOwnProperty('entitlements')) {
                return userEntitlements.some(function (entitlement) {
                  return form.entitlements.indexOf(entitlement.code) > -1 && entitlement.definite === isDefinite;
                });
              }
            });
          }

          // divide the applications forms into
          // - those the user is entitled to
          // - those the user may be entitled to
          // - those the user is not entitled to
          $scope.entitledApplications = getEntitledForms(true);
          $scope.maybeEntitledApplications = getEntitledForms(false);
          $scope.applications = _.difference(applications, $scope.entitledApplications, $scope.maybeEntitledApplications);

        }).then(function () {

          formModelService.listForms()
            .then(function (formModelInstances) {
              $scope.currentForms = formModelInstances;
              var formsArrayIds = formModelInstances.map(function (form) {
                return form.formTypeCode;
              });

              angular.forEach($scope.forms, function (form) {
                var currentId = form.id;
                form.nbCurrentForm = formsArrayIds.filter(function (formId) {
                  return currentId === formId;
                }).length;
              });
            });
        });

        $scope.fetchTimeLine = function (start, end) {
          if ($scope.canInvokeApi()) {

            API.get('/timeline?start=' + start.format('YYYY-MM-DD') + '&end=' + end.format('YYYY-MM-DD')).then(function (events) {
              var forms = events.data.filter(function (event) {
                return event.type === "FormData" && event.item.formTypeCode !== 'CSA';
              });
              $scope.events = forms;
              $scope.updateForms(forms);
            });
          }
        };

      }]);
