'use strict';
angular.module('kerp-forms.forms')
  .factory(
    'NL_FCA_FORM',
    [
      'FormUI',
      'fieldDefinitionService',
      'northLincsCareAssessmentCalculator',
      'sfSelect',
      'htmlService',
      'NL_FCA_CONDITIONS',
      '$window',

      function (
        FormUI,
        fieldDefinitionService,
        careAssessmentCalculator,
        sfSelect,
        htmlService,
        conditions,
        $window
      ) {

        const careAssessmentConstants = $window.kerpCfa.getConstants('NL_FCA');

        var formUI = new FormUI();

        /**
         * Recalculate the cost of care on submission because the user could use the pagination controls to change
         * an input field (without revisiting the calculation page) before submitting
         * @param dirtyModel
         * @param cleanModel
         */
        formUI.preSubmissionHandler = function (dirtyModel, cleanModel) {
          careAssessmentCalculator.recalculate(dirtyModel, cleanModel);
        };

        formUI.getHiddenPages = function (model) {
          var intro = (model.person || {}).intro || {};
          if (!intro.discloseFinances) {
            return [];
          }

          var withholdFinancialDetails = intro.discloseFinances.provideFinancialDetails !== "agree";
          return withholdFinancialDetails ? [3, 4, 5, 6, 7, 8, 9, 10, 11] : [];
        };

        formUI.setForm([
          {
            type: 'section',
            condition: 'model.page === 1',
            page: 1,
            htmlClass: 'row',
            items: [
              {
                key: 'schemaVersion',
                type: 'schemaversion',
              },
              {
                type: 'fieldset',
                htmlClass: 'col-md-12',
                items: [
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_01_intro.html'
                  },
                ]
              },
              {
                type: 'fieldset',
                title: 'People acting on your behalf',
                htmlClass: 'col-md-12',
                items: [
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/NL_FCA/templates/agent.html'
                  },
                  'agent.hasAppointee',
                  {
                    type: 'help',
                    helpvalue: "<div class='alert alert-info html-view-remove'>Appointeeship for State Benefits gives a person the right to deal with the benefits of someone who cannot manage their own affairs because they\'re mentally incapable or severely disabled. Additional information is available on <a href=\"https://www.gov.uk/become-appointee-for-someone-claiming-benefits\">the government website</a>.</div>"
                  },
                  {
                    condition: 'model.agent.hasAppointee',
                    key: 'agent.appointeeDetails'
                  },
                  'agent.hasDeputy',
                  {
                    type: "help",
                    helpvalue: "<div class='alert alert-info html-view-remove'>You are someone’s deputy if they \'lack mental capacity\'. This means they cannot make a decision for themselves at the time it needs to be made. They may still be able to make decisions for themselves at certain times. Additional information is available on <a href=\"https://www.gov.uk/become-deputy\">the government website</a>.</div>"
                  },
                  {
                    condition: 'model.agent.hasDeputy',
                    key: 'agent.deputyDetails'
                  },
                  'agent.hasPowerOfAttorney',
                  {
                    type: "help",
                    helpvalue: "<div class='alert alert-info html-view-remove'>A power of attorney is a legal document that allows someone to make decisions for you, or act on your behalf, if you\'re no longer able to or if you no longer want to make your own decisions. Additional information is available on <a href=\"https://www.gov.uk/power-of-attorney\">the government website</a>.</div>"
                  },
                  {
                    condition: 'model.agent.hasPowerOfAttorney',
                    key: 'agent.powerOfAttorneyDetails'
                  },
                  'agent.isCompletedByAgent',
                  {
                    type: "help",
                    helpvalue: "<div class='alert alert-info html-view-remove'>Only answer \'Yes\' to this question if you are <b>not</b> acting in any of the capacities described above.</div>"
                  },
                  {
                    condition: 'model.agent.isCompletedByAgent',
                    key: 'agent.completedByDetails'
                  }
                ]
              },
            ]
          },
          {
            type: 'section',
            condition: 'model.page === 2',
            page: 2,
            htmlClass: "row",
            items: [
              {
                type: "fieldset",
                htmlClass: "col-md-12",
                items: [
                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/NL_FCA/templates/antifraud-declaration.html"
                  },
                  'antifraudDeclaration'
                ]
              },
              {
                type: "fieldset",
                htmlClass: "col-md-12",
                items: [
                  'person.details.contactPreference'
                ]
              },
              {
                type: 'fieldset',
                htmlClass: 'col-md-12',
                title: 'About you',
                items: [
                  {
                    type: 'template',
                    htmlClass: 'divTitle',
                    templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_02_about-you.html'
                  },
                  'person.details.firstName',
                  'person.details.middleName',
                  'person.details.lastName',
                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/NL_FCA/templates/addressLookup.html"
                  },
                  // FIXME: these fields are already defined in the addressSuggestion directive's template, but they are not included in the submitted model unless they are repeated here
                  {
                    key: 'person.details.address.line1',
                    title: 'Address line 1',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.line2',
                    title: 'Address line 2',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.line3',
                    title: 'Address line 3',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.postcode',
                    title: 'Postcode',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.uprn',
                    title: 'Property Reference',
                    type: "hidden"
                  },
                  'person.details.homePhoneNumber',
                  'person.details.mobilePhoneNumber',
                  'person.details.email',
                  'person.details.nhsNumber',
                  {
                    key: 'person.details.dob',
                    description: 'We need this information to calculate your State Pension age'
                  },
                  'person.details.nextOfKin',
                  {
                    key: 'person.details.nextOfKinDetails',
                    condition: 'model.person.details.nextOfKin'
                  },
                  'person.intro.discloseFinances',
                  {
                    key: 'person.details.nationalInsuranceNumber',
                    condition: 'model.person.intro.discloseFinances.provideFinancialDetails === "agree"',
                  },
                  {
                    key: 'person.intro.hasPartner',
                    condition: 'model.person.intro.discloseFinances.provideFinancialDetails === "agree"',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/NL_FCA/templates/partnerDescription.html')
                  }
                ]
              },
              {
                type: 'fieldset',
                htmlClass: 'col-md-12',
                title: 'About your partner',
                condition: 'model.person.intro.hasPartner && model.person.intro.discloseFinances.provideFinancialDetails === "agree"',
                items: [
                  'partner.details.sameAddress',
                  {
                    type: 'fieldset',
                    condition: 'model.partner.details.sameAddress === true',
                    items: [
                      'partner.details.firstName',
                      'partner.details.middleName',
                      'partner.details.lastName',
                      'partner.details.homePhoneNumber',
                      'partner.details.mobilePhoneNumber',
                      'partner.details.email',
                      'partner.details.nhsNumber',
                      'partner.details.dob',
                      'partner.intro.discloseFinances',
                      {
                        key: 'partner.details.nationalInsuranceNumber',
                        condition: 'model.partner.intro.discloseFinances',
                      }
                    ],
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 3',
            page: 3,
            title: 'People living with you',
            items: [
              {
                key: 'peopleLivingWithYou.hasDependentChildren',
                description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/NL_FCA/templates/dependentChildrenDescription.html')
              },
              {
                key: 'peopleLivingWithYou.dependentChildren',
                condition: 'model.peopleLivingWithYou.hasDependentChildren',
                add: "Add Child",
                title: 'Dependent children details',
                validationMessage: 'Complete all required fields for at least one child'
              },
              {
                key: 'peopleLivingWithYou.hasOtherPeople',
                description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/NL_FCA/templates/nonDependentsDescription.html')
              },
              {
                key: 'peopleLivingWithYou.otherPeople',
                condition: 'model.peopleLivingWithYou.hasOtherPeople',
                add: "Add Person",
                title: 'Details of other people living with you',
                validationMessage: 'Complete all required fields for at least one person'
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 4',
            page: 4,
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your income and benefits',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_04_income-intro.html'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Wages/Salary</h4>"
                      },
                      {
                        key: 'person.income.hasWage',
                        title: 'Are you currently employed / self-employed?'
                      },
                      {
                        key: 'person.income.wages',
                        condition: 'model.person.income.hasWage',
                        add: "Add salary/wage",
                        title: 'Wages/Salary',
                        validationMessage: 'Enter details of at least one employer'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Private/Works Pensions</h4>"
                      },
                      {
                        key: 'person.income.hasPrivatePension',
                        title: 'Are you in receipt of private/works pension(s)?'
                      },
                      {
                        key: 'person.income.privatePensions',
                        condition: 'model.person.income.hasPrivatePension',
                        add: "Add more",
                        title: 'Private/Works Pensions',
                        validationMessage: 'Enter details of at least one private/works pension'
                      },

                      {
                        key: 'person.income.hasUniversalCredit',
                        title: 'Are you in receipt of Universal Credit?'
                      },

                      {
                        type: 'template',
                        templateUrl: 'modules/forms/scripts/services/forms/NL_FCA/templates/universalCreditIntro.html',
                        condition: 'model.person.income.hasUniversalCredit'
                      },
                      {
                        key: 'person.income.universalCreditBreakdown',
                        notitle: true,
                        title: 'Universal Credit Allowances',
                        condition: 'model.person.income.hasUniversalCredit'
                      },

                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.retirementPension'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.incomeSupportPayment'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.incapacityBenefit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.employmentSupport'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.jobSeekersAllowance'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.industrialInjuriesBenefit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.rentalIncome'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.workingTaxCredit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.childTaxCredit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.maintenance'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.warPension'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.warWidowsPension'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.warSpecialPayment'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.childBenefit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.carersAllowance'}),

                      fieldDefinitionService.groups.paymentFormField({
                        key:'person.income.attendanceAllowance',
                        condition: conditions.toString('person.income.attendanceAllowance')
                      }),
                      fieldDefinitionService.groups.paymentFormField({
                        key:'person.income.dlaCare',
                        condition: conditions.toString('person.income.dlaCare')
                      }),
                      fieldDefinitionService.groups.paymentFormField({
                        key:'person.income.pipDailyLiving',
                        condition: conditions.toString('person.income.pipDailyLiving')
                      }),

                      fieldDefinitionService.groups.paymentFormField({key:'person.income.mobilityAllowance'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.pensionGuaranteeCredit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'person.income.pensionSavingsCredit'}),
                      {
                        key: 'person.income.pensionSavingsCreditInformation',
                        condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances && model.person.income.pensionSavingsCredit'
                      },

                      'person.income.hasOtherBenefit',
                      {
                        key: 'person.income.otherBenefit',
                        condition: 'model.person.income.hasOtherBenefit'
                      },

                      'person.income.ongoingApplications',
                      {
                        key: 'person.income.ongoingApplicationsInformation',
                        condition: 'model.person.income.ongoingApplications'
                      },
                      {
                        key: 'person.details.transferHalfPensionToPartner',
                        title: 'Disregard half pension and transfer to partner?',
                        description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/NL_FCA/templates/transferHalfPensionToPartner.html'),
                        condition: 'model.partner.intro.discloseFinances'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Your partner's income and benefits",
                    htmlClass: "col-md-6",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    items: [
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Wages/Salary</h4>"
                      },
                      {
                        key: 'partner.income.hasWage',
                        title: 'Is your partner currently employed?'
                      },
                      {
                        key: 'partner.income.wages',
                        condition: 'model.partner.income.hasWage',
                        add: "Add salary/wage",
                        title: 'Wages/Salary',
                        validationMessage: 'Enter details of at least one employer'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Private/Works Pensions</h4>"
                      },
                      {
                        key: 'partner.income.hasPrivatePension',
                        title: 'Is your partner in receipt of private/works pension(s)?'
                      },
                      {
                        key: 'partner.income.privatePensions',
                        condition: 'model.partner.income.hasPrivatePension',
                        add: "Add more",
                        title: 'Private/Works Pensions',
                        validationMessage: 'Enter details of at least one private/works pension'
                      },

                      {
                        key: 'partner.income.hasUniversalCredit',
                        title: 'Is your partner in receipt of Universal Credit?'
                      },

                      {
                        type: 'template',
                        templateUrl: 'modules/forms/scripts/services/forms/NL_FCA/templates/universalCreditIntro.html',
                        condition: 'model.partner.income.hasUniversalCredit'
                      },
                      {
                        key: 'partner.income.universalCreditBreakdown',
                        notitle: true,
                        title: 'Universal Credit Allowances',
                        condition: 'model.partner.income.hasUniversalCredit'
                      },

                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.retirementPension'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.incomeSupportPayment'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.incapacityBenefit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.employmentSupport'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.jobSeekersAllowance'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.industrialInjuriesBenefit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.rentalIncome'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.workingTaxCredit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.childTaxCredit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.maintenance'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.warPension'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.warWidowsPension'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.warSpecialPayment'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.childBenefit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key:'partner.income.carersAllowance'}),

                      fieldDefinitionService.groups.paymentFormField({
                        key:'partner.income.attendanceAllowance',
                        condition: conditions.toString('partner.income.attendanceAllowance')
                      }),
                      fieldDefinitionService.groups.paymentFormField({
                        key:'partner.income.dlaCare',
                        condition: conditions.toString('partner.income.dlaCare')
                      }),
                      fieldDefinitionService.groups.paymentFormField({
                        key:'partner.income.pipDailyLiving',
                        condition: conditions.toString('partner.income.pipDailyLiving')
                      }),

                      fieldDefinitionService.groups.paymentFormField({key:'partner.income.mobilityAllowance'}),
                      'partner.income.hasOtherBenefit',
                      {
                        key: 'partner.income.otherBenefit',
                        condition: 'model.partner.income.hasOtherBenefit'
                      },

                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'section',
            condition: 'model.page === 5',
            page: 5,
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your capital',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_03_capital-intro.html'
                      },
                      {
                        key: 'person.capital.hasSavingsInvestments',
                        title: 'Do you have any money or investments which are held in accounts where you are the sole named account holder?'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    title: "Your partner's capital",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'partner.capital.hasSavingsInvestments',
                        title: 'Does your partner have any money or investments which are held in accounts where they are the sole named account holder?'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Joint capital",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'joint.capital.hasSavingsInvestments',
                        title: 'Do you have any money or investments which are held in accounts where you and someone else are both named account holders?'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/NL_FCA/templates/capitalDescription.html'
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.person.capital.hasSavingsInvestments',
                        key: 'person.capital.savingsInvestments',
                        add: "Add account",
                        title: 'Give details of your savings and investments',
                        validationMessage: 'Enter details of at least one account'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.partner.capital.hasSavingsInvestments',
                        key: 'partner.capital.savingsInvestments',
                        add: "Add account",
                        title: "Give details of your partner's savings and investments",
                        validationMessage: 'Enter details of at least one account'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.joint.capital.hasSavingsInvestments',
                        key: 'joint.capital.savingsInvestments',
                        add: "Add account",
                        title: 'Give details of jointly owned savings and investments',
                        validationMessage: 'Enter details of at least one account'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your shareholdings',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'person.capital.hasShareholdings',
                        title: 'Are you the sole owner of any company shares?'
                      },
                      {
                        key: 'person.capital.shareholdings',
                        condition: 'model.person.capital.hasShareholdings',
                        add: "Add shareholding",
                        title: 'Give details of your shareholdings',
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    title: "Your partner's shareholdings",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'partner.capital.hasShareholdings',
                        title: 'Is your partner the sole owner of any company shares?'
                      },
                      {
                        key: 'partner.capital.shareholdings',
                        condition: 'model.partner.capital.hasShareholdings',
                        add: "Add shareholding",
                        title: "Give details of your partner's shareholdings",
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Joint shareholdings",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'joint.capital.hasShareholdings',
                        title: 'Do you and someone else jointly own any company shares?'
                      },
                      {
                        key: 'joint.capital.shareholdings',
                        condition: 'model.joint.capital.hasShareholdings',
                        add: "Add shareholding",
                        title: 'Give details of jointly owned shareholdings',
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 6',
            page: 6,
            title: 'Property/Land you own',
            items: [
              'person.property.ownership',
              {
                key: 'person.property.otherProperty',
                condition: 'model.person.property.ownership === "other"'
              },
              {
                key: 'person.property.value',
                condition: conditions.toString('person.property.value')
              },
              'person.property.hasAdditionalProperties',
              {
                key: 'person.property.additionalProperties',
                condition: 'model.person.property.hasAdditionalProperties',
                add: "Add property",
                title: 'We need to know value(s) of any property/properties, including land, owned and/or jointly owned by you other than your main home',
                validationMessage: 'Enter details of at least one property'
              },
              'person.property.additionalInformation'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 7',
            page: 7,
            title: 'Deprivation of Assets',
            items: [
              {
                type: 'template',
                htmlClass: 'divTitle',
                templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_12_capital_transfers-gifting-intro.html'
              },
              'deprivationOfAssets.hasDisposedOfAssets',
              {
                key: 'deprivationOfAssets.assetValue',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                key: 'deprivationOfAssets.disposedAssets',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/NL_FCA/templates/deprivationOfAssetsDescription.html',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                key: 'deprivationOfAssets.reasonForAssetsDisposal',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/NL_FCA/templates/deprivationOfAssetsReason.html',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 8',
            page: 8,
            title: 'Household Expenditure',
            items: [
              {
                type: 'template',
                htmlClass: 'divTitle',
                templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_05_outgoings-intro.html'
              },
              fieldDefinitionService.groups.periodicPaymentFormFields({
                key:'person.expenses.mortgageLessHousing',
                condition: '!model.person.income.universalCredit'
              }),
              fieldDefinitionService.groups.periodicPaymentFormFields({
                key:'person.expenses.mortgage',
                condition: 'model.person.income.universalCredit'
              }),

              fieldDefinitionService.groups.periodicPaymentFormFields({
                key:'person.expenses.rentLessHousing',
                condition: '!model.person.income.universalCredit'
              }),

              fieldDefinitionService.groups.periodicPaymentFormFields({
                key:'person.expenses.rent',
                condition: 'model.person.income.universalCredit'
              }),

              fieldDefinitionService.groups.paymentFormField({
                key:'person.expenses.councilTax'
              }),
              {
                type: 'template',
                htmlClass: 'divTitle',
                templateUrl: 'modules/forms/scripts/services/forms/NL_FCA/templates/annualCouncilTaxDescription.html'
              },

              fieldDefinitionService.groups.periodicPaymentFormFields({
                key:'person.expenses.homeInsurance',
                description: 'If you have a combined buildings and contents insurance policy, enter the total amount you pay'
              }),
              {
                key: 'person.expenses.isHomeInsuranceCombined',
                condition: "model.person.expenses.homeInsurance"
              },
              fieldDefinitionService.groups.periodicPaymentFormFields({
                key:'person.expenses.compulsoryCourtPayments'
              }),
              fieldDefinitionService.groups.periodicPaymentFormFields({
                key:'person.expenses.waterRates'
              }),
              fieldDefinitionService.groups.periodicPaymentFormFields({
                key:'person.expenses.sewerage'
              })
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 9',
            page: 9,
            title: 'Disability-Related Expenses',
            items: [
              {
                type: 'fieldset',
                items: [
                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/NL_FCA/templates/dre.html"
                  },
                  'person.expenses.disabilityRelated.hasOtherDRE',
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/NL_FCA/templates/dreGuidance.html',
                    condition: 'model.person.expenses.disabilityRelated.hasOtherDRE'
                  },
                  {
                    key: 'person.expenses.disabilityRelated.otherDRE',
                    condition: 'model.person.expenses.disabilityRelated.hasOtherDRE'
                  },
                  {
                    key: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                    htmlClass: 'invisible'
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 10',
            page: 10,
            title: 'Other information',
            items: [
              'otherInformation'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 11',
            page: 11,
            title: 'Declaration',
            items: [
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/NL_FCA/templates/declaration.html"
              },
              'declaration'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 12',
            page: 12,
            title: 'Cost of care',
            items: [
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/NL_FCA/templates/costOfCareIntro.html'
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Non-residential',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.nonResidential.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'NL_FCA',
                        calculator: careAssessmentCalculator,
                        careType: careAssessmentConstants.careTypes.nonResidential,
                        title: 'Maximum non-residential care contribution'
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.nonResidential.workings']
                      }
                    ]
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: "Short Stay Residential - 0 to 4 weeks",
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.shortStayResidentialWeek1.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'NL_FCA',
                        calculator: careAssessmentCalculator,
                        careType: careAssessmentConstants.careTypes.shortStayResidentialWeek1,
                        title: 'Maximum residential care contribution'
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.shortStayResidentialWeek1.workings']
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Short Stay Residential - 5+ weeks",
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.shortStayResidentialWeek5.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'NL_FCA',
                        calculator: careAssessmentCalculator,
                        careType: careAssessmentConstants.careTypes.shortStayResidentialWeek5,
                        title: 'Maximum residential care contribution'
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.shortStayResidentialWeek5.workings']
                      }
                    ]
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: "Permanent Stay Residential - 0 to 4 weeks",
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.longStayResidentialWeek1.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'NL_FCA',
                        calculator: careAssessmentCalculator,
                        careType: careAssessmentConstants.careTypes.longStayResidentialWeek1,
                        title: 'Maximum residential care contribution'
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.longStayResidentialWeek1.workings']
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Permanent Stay Residential - 5+ weeks",
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.longStayResidentialWeek5.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'NL_FCA',
                        calculator: careAssessmentCalculator,
                        careType: careAssessmentConstants.careTypes.longStayResidentialWeek5,
                        title: 'Maximum residential care contribution'
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.longStayResidentialWeek5.workings']
                      }
                    ]
                  },
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    htmlClass: "col-md-12",
                    items: [
                      {
                        key: "careAssessment.careDecision",
                        title: "Care package preference",
                        type: "radios",
                        titleMap: {
                          continue: "I want to submit this full care financial assistance application to North Lincolnshire Council and proceed with an assessment of my care needs.",
                          continueWithoutFinancialAssistance: "I do not wish to provide my financial details and want the Council to arrange my care. I agree to pay the full costs of my care.",
                          cancel: "I do not wish to continue with my care needs assessment at this time."
                        },
                        description: 'Based on the calculations above please select one of these options'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: "section",
            condition: "areFormNavigationButtonsVisible()",
            items: [
              {
                type: "actions",
                htmlClass: "formPaginationButtons",
                items: [
                  {
                    type: "button",
                    style: "btn-default",
                    title: "Previous",
                    onClick: "prev()"
                  },
                  {
                    type: "button",
                    style: "btn-primary",
                    title: "Next",
                    onClick: "next()"
                  }]
              }
            ]
          }
        ]);

        return formUI;
      }]);
