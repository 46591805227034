'use strict';

function formConverterService(
  sfSelect,
  schemaFormHelperService,
  FormErrorType,
  $exceptionHandler,
  $rootScope,
  $interpolate,
  htmlService,
  $sanitize,
  numberUtils,
  dateUtils
) {

  function generateFieldMarkup(fieldDefinition, value, hideUndefinedFields, schema) {

    if (!fieldDefinition) {
      // KERP-3246
      console.warn("Failed to find definition of field with value:", value);
      return;
    }

    var valueText = '';
    var label = (fieldDefinition.title || '').trim();

    var currencyPrefix = (fieldDefinition.schema['x-schema-form'] || {}).fieldAddonLeft || '';
    var poundsOnly = (fieldDefinition.schema['x-schema-form'] || {}).poundsOnly || '';

    if (angular.isUndefined(value) && (hideUndefinedFields || label.length === 0)) {
      if (fieldDefinition.schema.whenUndefined) {
        valueText = fieldDefinition.schema.whenUndefined;
      } else {
        console.warn('Field with key', fieldDefinition.key, 'is omitted from the HTML view');
        return valueText;
      }

    } else if ((angular.isString(value) && value.trim().length === 0) || angular.isUndefined(value) ||
      (fieldDefinition.type === 'number' && !numberUtils.isNumeric(value))) {
      valueText = fieldDefinition.schema.whenUndefined || '-';

    } else if (angular.isArray(value)) {

      if (fieldDefinition.schema.convertKeysToLabels) {

        var keySource = getSchemaForKey(fieldDefinition.schema.convertKeysToLabels, schema);
        value = value.map(function (fieldValue) {
          return replaceKeyWithTitle(keySource, fieldValue);
        });

      } else {

        value = value.map(function (fieldValue) {
          return replaceValueWithLabel(fieldDefinition, fieldValue);
        });
      }

      valueText = value.join(', ');

    } else if (typeof value === 'boolean') {
      valueText = value ? 'Yes' : 'No';

    } else if (fieldDefinition.dateFormat && fieldDefinition.dateFormat !== 'DD/MM/YYYY') {
      valueText = dateUtils.convertDateString(value, fieldDefinition.dateFormat, 'DD/MM/YYYY');

    } else {
      valueText += replaceValueWithLabel(fieldDefinition, value);

      if (currencyPrefix) {
        if (!isNaN(parseFloat(valueText).toFixed(2))) {
          if (poundsOnly) {
            valueText = currencyPrefix + parseInt(valueText);
          } else {
            valueText = currencyPrefix + parseFloat(valueText).toFixed(2);
          }
        }
      }


    }

    var safeFieldValue = $sanitize(valueText);
    var templateModel = {key: label, value: safeFieldValue};
    return htmlService.interpolateTemplate('modules/forms/views/pdf/field.html', {model: templateModel});
  }

  function getSchemaForKey(key, schema) {
    return schemaFormHelperService.findConfigInSchema(schema, key.split('.'));
  }

  function replaceKeyWithTitle(schema, key) {
    return (((schema || {}).properties || {})[key] || {}).title || key;
  }

  function replaceValueWithLabel(fieldDefinition, fieldValue) {

    if (fieldDefinition.titleMap) {

      var matchingTitleMap = _.find(fieldDefinition.titleMap, function (titleMapEntry) {
        return titleMapEntry.value === fieldValue;
      });

      if (matchingTitleMap) {
        return matchingTitleMap.name;
      }
    }
    return fieldValue;
  }

  function formAsSimplifiedHtml(formConditionsService, schemaFormForm, schemaFormSchema, formFields, options) {

    options = options || {};
    var html = '';

    var aggregateFormDefinition = schemaFormHelperService.mergeFormAndSchema(schemaFormForm, schemaFormSchema);

    // evaluate the HTML as an Angular template and add the form model to the template's scope
    var templateScope = $rootScope.$new(true);
    templateScope.model = formFields;

    if (options.externalReference) {
      html += '<div class="alert alert-info external-ref">The reference number for this application is <span class="ref-num-display">' +
        options.externalReference + '</span></div>';
    }

    if (options.lastUpdated) {
      var date = new Date(Date.parse(options.lastUpdated));
      var dateString = date.toLocaleString();

      html += '<div class="alert alert-info last-updated">The application was submitted at <span class="last-updated-display">' +
        dateString + '</span></div>';
    }

    function generateHtmlByPageNo(mergedFormAndSchemaPage) {

      var hasVisibleInputFields = schemaFormHelperService.hasVisibleFields(formFields,
        mergedFormAndSchemaPage, formConditionsService, options.hideUndefinedFields).input;
      var isInfoPage = schemaFormHelperService.isInfoPage(formFields, formConditionsService, mergedFormAndSchemaPage);

      schemaFormHelperService.forEachFieldInForm(mergedFormAndSchemaPage, formFields, {
        conditionLibrary: formConditionsService,
        htmlHandler: function (content, formField) {

          var includeInPDF = formField.includeInPDF;

          if (hasVisibleInputFields || isInfoPage || includeInPDF) {

            var $content = $('<div></div>').html(content);
            $content.find('.html-view-remove').remove();

            var templateOutput = $interpolate($content.html())(templateScope);
            if (templateOutput.trim()) {
              console.log("Generating PDF section for template output", templateOutput);
              html += '<div class="form-section">' + templateOutput + '</div>';
            }
          }

        },
        fieldHandler: function (field) {
          var fieldValue = sfSelect(field.key, formFields);

          const type = (field || {}).type;
          if (type === 'schemaversion') {
            // don't add this to the review html / pdf
            return;
          }

          traverse(fieldValue, field, function (fieldDefinition, value) {
            html += generateFieldMarkup(fieldDefinition, value, options.hideUndefinedFields, schemaFormSchema);
          });
        }
      });
    }

    aggregateFormDefinition.forEach(function (formPage) {
      generateHtmlByPageNo(formPage);
    });

    if (options.evidence) {
      html += '<h2>Required Evidence</h2>';

      angular.forEach(options.evidence, function (evidence) {
        html += '<h3>' + evidence.label + '</h3>';
        html += '<div>' + evidence.text + '</div>';
      });
    }

    return html;
  }

  function traverse(modelValue, form, fieldRenderer) {

    if (!form.schema) {

      // user should not be affected, but we should log this error
      // in order to avoid divergence between schema and form
      var e = new Error(FormErrorType.FORM_SCHEMA_MISMATCH_ERROR);
      $exceptionHandler(e, 'Form / schema mismatch error', undefined, {
        data: form
      });

      return;
    }

    var dataType = form.schema.type;

    // If it's not an array of objects, render the values comma-separated on a single line, instead of
    // putting each value on a different line with the same label. For example "servicesCovered" in FCA
    var isPrimitiveArray = dataType === 'array' &&
      (!modelValue || !modelValue.length || !angular.isObject(modelValue[0]));

    if ((dataType !== 'array' && dataType !== 'object') || isPrimitiveArray) {
      fieldRenderer(form, modelValue);

    } else {
      angular.forEach(modelValue, function (fieldValue, propertyName) {

        if (propertyName === '$$hashKey') {
          // Since version 1.0-alpha, ASF adds a '$$hashkey' property to some objects that we don't want to render
          return;

        } else if (dataType === 'array') {

          if (form.items.length > 1) {
            angular.forEach(form.items, function(item, index) {

              //  skip if item is not a form field (e.g. help, template etc)
              if (item.key) {
                var fieldName = item.key[item.key.length - 1];
                traverse(fieldValue[fieldName], item, fieldRenderer);
              }

            });

          } else {
            var arrayFieldDefinition = form.items[0];
            traverse(fieldValue, arrayFieldDefinition, fieldRenderer);
          }
          // adds a collapsed empty row beneath each completed array
          // for improved legibility

          fieldRenderer({
            type: 'string',
            title: ' ',
            schema: {
              whenUndefined: ' ',
            }
          });

        } else if (dataType === 'object') {

          var fieldDefinition = _.find(form.items, function (field) {
            // skip form items that are not form data schema objects
            var fieldName = field.key ? field.key[field.key.length - 1] : '';
            return propertyName === fieldName;
          });

          fieldRenderer(fieldDefinition, fieldValue);

        } else {
          throw new Error("Failed to generate markup for field '" + propertyName + "' with value: " + fieldValue);
        }
      });
    }
  }

  return {
    toPdfHtml: function () {
      return '' +
        '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">' +
        '<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en" lang="en">' +
        '  <head>' +
        '    <style type="text/css">' +
        '      body { font-family: sans-serif; }' +
        '      table {  margin-bottom: 15px !important; }' +
        '      table + table { margin-top: -15px !important; }' +
        '      table + table td { border-top: 0 !important; }' +
        '      td { white-space: normal; }' +
        '      .form-section { font-weight: bold; }' +
        '      .kerp-page-break-after { page-break-after: always; }' +
        '    </style>' +
        '  </head>' +
        '  <body>' +
        formAsSimplifiedHtml.apply(this, arguments) +
        '  </body>' +
        '</html>';
    },
    toHtml: function () {
      var formAsSimplifiedHtmlArguments = Array.prototype.slice.call(arguments);
      formAsSimplifiedHtmlArguments.push({hideUndefinedFields: true});
      return formAsSimplifiedHtml.apply(this, formAsSimplifiedHtmlArguments);
    }
  };
}

formConverterService.$inject = [
  'sfSelect',
  'schemaFormHelperService',
  'FormErrorType',
  '$exceptionHandler',
  '$rootScope',
  '$interpolate',
  'htmlService',
  '$sanitize',
  'numberUtils',
  'dateUtils'
];

angular.module('kerp-forms.forms').factory('formConverterService', formConverterService);
