'use strict';

angular.module('kerp-forms.forms').service(
  'southamptonCareAssessmentCalculator',

  [
    '$window',
    'calculationResultService',

    function (
      $window,
      calculationResultService
    ) {

      function saveCalcResults(careType, calcResults, model) {
        calculationResultService.saveCalcResults(careType, calcResults, model);
      }

      var constants = $window.kerpCfa.getConstants('SOT_FCA');

      this.recalculate = function (dirtyModel, cleanModel) {

        const recalculateCareTypes = Object.keys(constants.careTypes);
        const self = this;

        recalculateCareTypes.forEach(function (careType) {
          const calcResults = self.calculate(cleanModel, null, careType);
          saveCalcResults(careType, calcResults, dirtyModel);
          saveCalcResults(careType, calcResults, cleanModel);
        });
      };

      this.calculate = function (fields, isShortForm, careType) {
        return $window.kerpCfa.southampton.calculator(fields, isShortForm, careType);
      };
    }
  ]
);
