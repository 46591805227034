'use strict';
angular.module('kerp-forms.forms')
  .factory(
    'LI_RFCA_FORM',
    [
      'FormUI',
      'fieldDefinitionService',
      'LI_RFCA_CONDITIONS',
      'lincolnshireCareAssessmentCalculator',
      'sfSelect',
      'htmlService',
      '$window',
      'configuration',
      'objectUtils',

      function (
        FormUI,
        fieldDefinitionService,
        conditions,
        careAssessmentCalculator,
        sfSelect,
        htmlService,
        $window,
        configuration,
        objectUtils
      ) {

        var constants = $window.kerpCfa.getConstants('LI_RFCA');

        var formUI = new FormUI();
        var nonResidential = constants.careTypes.nonResidential;

        function meansTestedBenefitPremia (isApplicant, key, name) {
          const primary = isApplicant ? 'person' : 'partner';
          const secondary = isApplicant ? 'partner' : 'person';

          const partnerDisclosesCondition = isApplicant ? 'model.partner.intro.discloseFinances && ' : '';

          return {
            type: 'fieldset',
            condition: `model.${primary}.income.${key} && !model.${secondary}.income.${key}IsJoint`,
            items: [
              {
                key: `${primary}.income.${key}IncludesSDP`,
                title: `${isApplicant ? 'Do you' : 'Does your partner'} receive a Severe Disability Premium as part of ${isApplicant ? 'your' : 'your partner\'s'} ${name}?`
              },
              {
                key: `${secondary}.income.${key}IncludesSDP`,
                title: `${isApplicant ? 'Does your partner' : 'Do you'} receive a Severe Disability Premium as part of ${isApplicant ? 'your' : 'your partner\'s'} ${name}?`,
                condition: `${partnerDisclosesCondition}model.${primary}.income.${key}IsJoint`
              },
              {
                key: `${primary}.income.${key}IncludesCarersPremium`,
                title: `${isApplicant ? 'Do you' : 'Does your partner'} receive a Carers Premium as part of ${isApplicant ? 'your' : 'your partner\'s'} ${name}?`
              },
              {
                key: `${secondary}.income.${key}IncludesCarersPremium`,
                title: `${isApplicant ? 'Does your partner' : 'Do you'} receive a Carers Premium as part of ${isApplicant ? 'your' : 'your partner\'s'} ${name}?`,
                condition: `${partnerDisclosesCondition}model.${primary}.income.${key}IsJoint`
              },
            ]
          };
        }

        function costOfCareItems() {
          var costOfCareItemList = [
            {
              type: 'template',
              templateUrl: 'modules/forms/scripts/services/forms/LI_CA/templates/costOfCareIntro.html'
            },
            'careAssessment.showCaretypes',
            'careAssessment.selectCaretype',
            {
              type: 'section',
              htmlClass: "row",
              items: [
                {
                  type: "fieldset",
                  title: 'Non-residential',
                  htmlClass: "col-md-12",
                  condition: "model.careAssessment.showCaretypes !== 'residential'",
                  items: [
                    {
                      type: 'template',
                      templateUrl: 'modules/forms/scripts/services/forms/LI_RFCA/templates/selfFunding.html',
                      condition: 'model.person.details.currentCareIsSelfFunded === true'
                    },
                    {
                      key: 'careAssessment.nonResidential.maxContribution',
                      type: 'careAssessmentResult',
                      formTypeCode: 'LI_RFCA',
                      calculator: careAssessmentCalculator,
                      careType: constants.careTypes.nonResidential,
                      title: 'Maximum non-residential care contribution',
                      condition: conditions.toString('showNonResidentialCostOfCare')
                    },
                    {
                      type: "fieldset",
                      htmlClass: 'margin-top20 collapsible',
                      notitle: true,
                      collapse: true,
                      items: [
                        'careAssessment.nonResidential.workings'
                      ],
                      condition: conditions.toString('showNonResidentialBreakdown')
                    }
                  ]
                }
              ]
            },
            {
              type: 'section',
              htmlClass: "row",
              items: [
                {
                  type: "fieldset",
                  title: "Residential Temporary",
                  htmlClass: "col-md-12",
                  condition: "model.careAssessment.showCaretypes !== 'nonResidential'",
                  items: [
                    {
                      type: 'template',
                      templateUrl: 'modules/forms/scripts/services/forms/LI_RFCA/templates/selfFunding.html',
                      condition: 'model.person.details.currentCareIsSelfFunded === true'
                    },
                    {
                      type: 'template',
                      templateUrl: 'modules/forms/scripts/services/forms/LI_RFCA/templates/jointJSAorISInReceipt.html',
                      condition: conditions.toString('jointJSAorISInReceipt')
                    },
                    {
                      key: 'careAssessment.temporaryResidential.maxContribution',
                      type: 'careAssessmentResult',
                      formTypeCode: 'LI_RFCA',
                      calculator: careAssessmentCalculator,
                      careType: constants.careTypes.temporaryResidential,
                      title: 'Maximum temporary residential care contribution',
                      condition: conditions.toString('showTempResidentialCostOfCare')
                    },
                    {
                      type: "fieldset",
                      htmlClass: 'margin-top20 collapsible',
                      notitle: true,
                      collapse: true,
                      items: ['careAssessment.temporaryResidential.workings'],
                      condition: conditions.toString('showTempResidentialBreakdown')
                    }
                  ]
                },

                {
                  type: "fieldset",
                  title: "Residential Permanent (week 1-12)",
                  htmlClass: "col-md-12",
                  condition: "model.careAssessment.showCaretypes !== 'nonResidential'",
                  items: [
                    {
                      type: 'template',
                      templateUrl: 'modules/forms/scripts/services/forms/LI_RFCA/templates/selfFunding.html',
                      condition: 'model.person.details.currentCareIsSelfFunded === true'
                    },
                    {
                      type: 'template',
                      templateUrl: 'modules/forms/scripts/services/forms/LI_RFCA/templates/jointJSAorISInReceipt.html',
                      condition: conditions.toString('jointJSAorISInReceipt')
                    },
                    {
                      key: 'careAssessment.permWeek1Residential.maxContribution',
                      type: 'careAssessmentResult',
                      formTypeCode: 'LI_RFCA',
                      calculator: careAssessmentCalculator,
                      careType: constants.careTypes.permWeek1Residential,
                      title: 'Maximum permanent residential care contribution',
                      condition: conditions.toString('showPerm1ResidentialCostOfCare')

                    },
                    {
                      type: "fieldset",
                      htmlClass: 'margin-top20 collapsible',
                      notitle: true,
                      collapse: true,
                      items: ['careAssessment.permWeek1Residential.workings'],
                      condition: conditions.toString('showPerm1ResidentialBreakdown')
                    }
                  ]
                },
                {
                  type: "fieldset",
                  title: "Residential Permanent (weeks 13+)",
                  htmlClass: "col-md-12",
                  condition: "model.careAssessment.showCaretypes !== 'nonResidential'",
                  items: [
                    {
                      type: 'template',
                      templateUrl: 'modules/forms/scripts/services/forms/LI_RFCA/templates/selfFunding.html',
                      condition: 'model.person.details.currentCareIsSelfFunded === true'
                    },
                    {
                      type: 'template',
                      templateUrl: 'modules/forms/scripts/services/forms/LI_RFCA/templates/jointJSAorISInReceipt.html',
                      condition: conditions.toString('jointJSAorISInReceipt')
                    },
                    {
                      key: 'careAssessment.permWeek13Residential.maxContribution',
                      type: 'careAssessmentResult',
                      formTypeCode: 'LI_RFCA',
                      calculator: careAssessmentCalculator,
                      careType: constants.careTypes.permWeek13Residential,
                      title: 'Maximum permanent residential care contribution',
                      condition: conditions.toString('showPerm13ResidentialCostOfCare')
                    },
                    {
                      type: "fieldset",
                      htmlClass: 'margin-top20 collapsible',
                      notitle: true,
                      collapse: true,
                      items: ['careAssessment.permWeek13Residential.workings'],
                      condition: conditions.toString('showPerm13ResidentialBreakdown')
                    }
                  ]
                }
              ]
            },

            {
              type: 'template',
              templateUrl: 'modules/forms/scripts/services/forms/LI_RFCA/templates/costOfCareHelp.html'
            },
            {
              key: "careAssessment.careDecision",
              title: "Care package preference",
              type: "radios",
              titleMap: {
                continue: "I want to submit this full care financial assistance application to Lincolnshire County Council and continue with an assessment of my care needs.",
                continueWithoutFinancialAssistance: "I want to continue with a care needs assessment without applying for financial assistance. I agree to pay the full costs of my care and I understand this will incur a one-off arrangement fee of £445 (<strong>Please note:</strong> The arrangement fee does not apply to <strong>residential</strong> care packages)."
              },
              description: 'Based on the calculations above please select one of these options'
            }
          ];

          return costOfCareItemList;
        }

        formUI.getHiddenPages = function (model) {
          const provideFinancialDetails = objectUtils.getPropertyPath(model, 'person.intro.provideFinancialDetails');
          var withholdFinancialDetailsAndPayFullCost = provideFinancialDetails === 'payFullCost';
          var withholdFinancialDetailsAndArrangeOwnCare = provideFinancialDetails === 'arrangeMyOwn';
          var chargingExemption = model.person && model.person.details && model.person.details.chargingExemption;
          var hasChargingExemption = chargingExemption === 'exemptSection117' || chargingExemption === 'exemptCJD';

          let hiddenPages = [];

          if (withholdFinancialDetailsAndPayFullCost) {
            hiddenPages = [2, 3, 4, 5, 6, 7];
          } else if (withholdFinancialDetailsAndArrangeOwnCare || hasChargingExemption) {
            hiddenPages = [2, 3, 4, 5, 6, 7, 8];
          } else {
            hiddenPages = [];
          }

          return hiddenPages;
        };

        /**
         * Recalculate the cost of care on submission because the user could use the pagination controls to change
         * an input field (without revisiting the calculation page) before submitting
         * @param dirtyModel
         * @param cleanModel
         */
        formUI.preSubmissionHandler = function (dirtyModel, cleanModel) {
          careAssessmentCalculator.recalculate(dirtyModel, cleanModel, false);
        };


        formUI.setForm([
          {
            type: 'section',
            condition: 'model.page === 1',
            page: 1,
            htmlClass: "row",
            items: [
              {
                key: 'schemaVersion',
                type: 'schemaversion',
              },
              {
                type: "fieldset",
                htmlClass: "col-md-12",
                items: [
                  {
                    type: 'template',
                    htmlClass: 'divTitle',
                    templateUrl: 'modules/forms/scripts/services/forms/LI_RFCA/templates/cfa_animation_01_intro.html'
                  },
                  {
                    key: 'clientReference',
                    description: 'You will find your Mosaic reference number on letters or emails you have received from the Financial Assessment & Income Collection Team.',
                    validationMessage: 'Please enter a valid Mosaic reference number.'
                  }
                ]
              },
              {
                type: "fieldset",
                title: 'About you',
                htmlClass: "col-md-6",
                items: [
                  {
                    type: 'template',
                    htmlClass: 'divTitle',
                    templateUrl: 'modules/forms/scripts/services/forms/LI_RFCA/templates/cfa_animation_02_about-you.html'
                  },
                  'person.details.title',
                  'person.details.firstName',
                  'person.details.lastName',
                  'person.details.nationalInsuranceNumber',
                  'person.details.dob',
                  {
                    key: 'person.details.email',
                    description: 'If you provide an email address then this will be the default method of contacting you in the future if we need more information.'
                  },
                  'person.details.phoneNumber',
                  'person.details.mobilePhoneNumber',

                  'person.details.currentlyInCare',
                  {
                    key: 'person.details.currentCareIsSelfFunded',
                    condition: 'model.person.details.currentlyInCare',
                    description: 'Self-funders pay the whole cost of care fees directly to the care home provider. If you are currently residing in care but do not pay all of your cost of care fees directly to the care home please answer No to this question.'
                  },
                  {
                    key: 'person.details.currentCareFees',
                    condition: 'model.person.details.currentCareIsSelfFunded && model.person.details.currentCareIsSelfFunded'
                  },
                  {
                    key: 'person.details.currentCareFundedSince',
                    condition: 'model.person.details.currentCareIsSelfFunded && model.person.details.currentCareIsSelfFunded'
                  },
                  {
                    key: 'person.details.currentCareDatePaidUpTo',
                    condition: 'model.person.details.currentCareIsSelfFunded && model.person.details.currentCareIsSelfFunded'
                  },
                  {
                    key: 'person.details.currentCareAddress',
                    condition: 'model.person.details.currentlyInCare'
                  },

                  {
                    key: 'person.intro.carersAllowanceIsEntitled',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_RFCA/templates/applicantUnderlyingCarersAllowanceDescription.html'),
                    condition: 'model.person.details.currentlyInCare !== true'
                  },
                  {
                    key: 'person.intro.hasPartner',
                    condition: 'model.person.details.currentlyInCare !== true',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_CA/templates/partnerDescription.html')
                  },
                  {
                    key: 'partner.details.sameAddress',
                    condition: conditions.toString('hasPartner'),
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_CA/templates/partnerSameAddress.html')
                  },

                  {
                    key: 'person.details.partnerReceivesResidentialCare',
                    condition: conditions.toString('hasPartner')
                  },

                  {
                    key: 'person.details.partnerResidentialCareTerm',
                    condition: 'model.person.intro.hasPartner && model.person.details.partnerReceivesResidentialCare'
                  },

                  {
                    key: 'person.details.partnerReceivesCare',
                    condition: conditions.toString('hasPartner')
                  },
                  {
                    key: 'partner.intro.discloseFinances',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_RFCA/templates/jointBenefitsInformation.html'),
                    condition: conditions.toString('hasPartner')
                  },
                  {
                    key: 'person.details.jointBenefitAgreement',
                    condition: conditions.toString('hasPartner')
                  },
                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/LI_RFCA/templates/addressLookup.html",
                    homeAddressDescription: "If you have moved permanently into a Care Home please put the address of your previous main home."

                  },
                  // FIXME: these fields are already defined in the addressSuggestion directive's template, but they are not included in the submitted model unless they are repeated here
                  {
                    key: 'person.details.address.line1',
                    title: 'Address line 1',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.line2',
                    title: 'Address line 2',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.line3',
                    title: 'Address line 3',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.postcode',
                    title: 'Postcode',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.uprn',
                    title: 'Property Reference',
                    type: "hidden"
                  },

                ]
              },
              {
                type: "fieldset",
                title: 'About your partner',
                htmlClass: "col-md-6",
                items: [
                  'partner.details.title',
                  'partner.details.firstName',
                  'partner.details.lastName',
                  'partner.details.nationalInsuranceNumber',
                  'partner.details.dob',
                  'partner.details.mobilePhoneNumber',
                  {
                    key: 'partner.intro.carersAllowanceIsEntitled',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_RFCA/templates/partnerUnderlyingCarersAllowanceDescription.html')
                  }
                ],
                condition: conditions.toString('hasPartner')
              },
              {
                type: 'fieldset',
                title: "Declaring your financial circumstances",
                htmlClass: "col-md-12",
                items: [
                  'person.intro.provideFinancialDetails'
                ]
              },
              {
                type: "fieldset",
                title: 'Care contribution exemption',
                htmlClass: "col-md-12",
                items: [
                  {
                    title: 'With respect to care contributions, are you?',
                    key: 'person.details.chargingExemption',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_CA/templates/chargingExemption.html')
                  }
                ]
              }
            ]
          },
          {
            type: 'section',
            condition: 'model.page === 2',
            page: 2,
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your capital',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/LI_RFCA/templates/cfa_animation_03_capital-intro.html'
                      },
                      {
                        key: 'person.capital.hasSavingsInvestments',
                        title: 'Do you have any money or investments which are held in accounts where you are the sole named account holder?'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: conditions.toString('partnerDisclosesFinances'),
                    title: "Your partner's capital",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'partner.capital.hasSavingsInvestments',
                        title: 'Does your partner have any money or investments which are held in accounts where they are the sole named account holder?'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Joint capital",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'joint.capital.hasSavingsInvestments',
                        title: 'Do you have any money or investments which are held in accounts where you and someone else are both named account holders?'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/LI_RFCA/templates/capitalDescription.html'
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.person.capital.hasSavingsInvestments',
                        key: 'person.capital.savingsInvestments',
                        add: "Add account",
                        title: 'Your bank / building society / post office accounts',
                        validationMessage: 'Enter details of at least one account'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: conditions.toString('partnerDisclosesFinances'),
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.partner.capital.hasSavingsInvestments',
                        key: 'partner.capital.savingsInvestments',
                        add: "Add account",
                        title: "Your partner's bank / building society / post office accounts",
                        validationMessage: 'Enter details of at least one account'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.joint.capital.hasSavingsInvestments',
                        key: 'joint.capital.savingsInvestments',
                        add: "Add account",
                        title: 'Jointly owned bank / building society / post office accounts',
                        validationMessage: 'Enter details of at least one account'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'person.capital.hasBonds',
                        title: 'Are you the sole owner of any premium bonds / savings bonds / investment bonds / ISAs / trusts?',
                        description: '<strong>Please note:</strong> Any capital held in premium bonds / savings bonds / investment bonds / ISAs / trusts may be subject to further review. This could affect the amount you may need to contribute towards your care. If this is the case, the Council will contact you at a later date.'
                      },
                      {
                        condition: 'model.person.capital.hasBonds',
                        key: 'person.capital.bonds',
                        add: "Add investment",
                        title: 'Your premium bonds / savings bonds / investment bonds / ISAs / trusts',
                        validationMessage: 'Enter details of at least one investment'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: conditions.toString('partnerDisclosesFinances'),
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'partner.capital.hasBonds',
                        title: 'Is your partner the sole owner of any premium bonds / savings bonds / investment bonds / ISAs / trusts?',
                        description: '<strong>Please note:</strong> Any capital held in premium bonds / savings bonds / investment bonds / ISAs / trusts may be subject to further review. This could affect the amount you may need to contribute towards your care. If this is the case, the Council will contact you at a later date.'
                      },
                      {
                        condition: 'model.partner.capital.hasBonds',
                        key: 'partner.capital.bonds',
                        add: "Add investment",
                        title: "Your partner's premium bonds / savings bonds / investment bonds / ISAs / trusts",
                        validationMessage: 'Enter details of at least one investment'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'joint.capital.hasBonds',
                        title: 'Do you and someone else jointly own any savings bonds / investment bonds / trusts?',
                        description: '<strong>Please note:</strong> Any capital held in savings bonds / investment bonds / trusts may be subject to further review. This could affect the amount you may need to contribute towards your care. If this is the case, the Council will contact you at a later date.'
                      },
                      {
                        condition: 'model.joint.capital.hasBonds',
                        key: 'joint.capital.bonds',
                        add: "Add investment",
                        title: 'Jointly owned savings bonds / investment bonds / trusts',
                        validationMessage: 'Enter details of at least one investment'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your stocks / shares / national saving certificates',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'person.capital.hasShareholdings',
                        title: 'Are you the sole owner of any stocks / shares / national saving certificates?'
                      },
                      {
                        key: 'person.capital.shareholdings',
                        condition: 'model.person.capital.hasShareholdings',
                        add: "Add holding",
                        title: 'Your holdings',
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: conditions.toString('partnerDisclosesFinances'),
                    title: "Your partner's stocks / shares / national saving certificates",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'partner.capital.hasShareholdings',
                        title: 'Is your partner the sole owner of any stocks / shares / national saving certificates?'
                      },
                      {
                        key: 'partner.capital.shareholdings',
                        condition: 'model.partner.capital.hasShareholdings',
                        add: "Add holding",
                        title: "Your partner's holdings",
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: conditions.toString('partnerDisclosesFinances'),
                    title: "Joint stocks / shares / national saving certificates",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'joint.capital.hasShareholdings',
                        title: 'Do you and someone else jointly own any stocks / shares / national saving certificates?'
                      },
                      {
                        key: 'joint.capital.shareholdings',
                        condition: 'model.joint.capital.hasShareholdings',
                        add: "Add holding",
                        title: 'Jointly owned holdings',
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'section',
            condition: 'model.page === 3',
            page: 3,
            items: [
              {
                type: 'fieldset',
                title: 'Where you live',
                items: [
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/LI_RFCA/templates/propertyGuidance.html'
                  },

                  {
                    key: 'person.property.ownership',
                    condition: 'model.person.details.currentlyInCare !== true'
                  },

                  {
                    key: 'person.property.ownershipOfPreviousProperty',
                    condition: 'model.person.details.currentlyInCare === true'
                  },

                  {
                    key: 'person.property.propertyType',
                    title: 'Please indicate the type of property that you live in',
                    condition: 'model.person.details.currentlyInCare !== true'
                  },

                  {
                    key: 'person.property.propertyType',
                    title: 'Please indicate the type of property that you were living in',
                    condition: 'model.person.details.currentlyInCare === true'
                  },

                  {
                    key: 'person.property.propertyTenancyAdditionalDetails',
                    condition: "model.person.property.ownership === 'other' || model.person.property.ownershipOfPreviousProperty === 'other'"
                  },

                  {
                    type: 'fieldset',
                    title: 'Property you rent',
                    items: [
                      'person.property.serviceChargesIncluded'
                    ],
                    condition: conditions.toString('propertyRented')
                  },

                  {
                    type: 'fieldset',
                    title: 'Property you own',
                    condition: conditions.toString('propertyOwned'),
                    items: [
                      {
                        key: 'person.property.partnerLivesInHome',
                        condition: 'model.person.details.currentlyInCare === true'
                      },
                      'person.property.soleOwner',
                      {
                        key: 'person.property.soleOwnerDetails',
                        condition: 'model.person.property.soleOwner === false'
                      },

                      'person.property.inTrust',
                      {
                        key: 'person.property.inTrustDetails',
                        condition: 'model.person.property.inTrust',
                      },

                      'person.property.equityReleaseScheme',
                      {
                        key: 'person.property.equityReleaseSchemeDetails',
                        condition: 'model.person.property.equityReleaseScheme',
                      },

                      'person.property.loansSecuredAgainst',
                      {
                        key: 'person.property.furtherInformation',
                        condition: 'model.person.property.loansSecuredAgainst',
                      },

                      'person.property.caravanLandValue',
                      'person.property.value'
                    ],
                  },

                  {
                    key: 'peopleLivingWithYou.hasOtherPeople',
                    title: 'Other than yourself, does anyone else live in your property?',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_RFCA/templates/nonDependentsDescription.html'),
                    condition: 'model.person.details.currentlyInCare !== true'
                  },

                  {
                    key: 'peopleLivingWithYou.hasOtherPeople',
                    title: 'Does anyone else live in the property?',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_RFCA/templates/nonDependentsDescription.html'),
                    condition: 'model.person.details.currentlyInCare === true'
                  },

                  {
                    key: 'peopleLivingWithYou.otherPeople',
                    condition: 'model.peopleLivingWithYou.hasOtherPeople && model.person.details.currentlyInCare !== true',
                    add: "Add Person",
                    title: 'Details of other people living with you',
                    validationMessage: 'Complete all required fields for at least one person'
                  },

                  {
                    key: 'peopleLivingWithYou.otherPeople',
                    condition: 'model.peopleLivingWithYou.hasOtherPeople && model.person.details.currentlyInCare === true',
                    add: "Add Person",
                    title: 'Details of other people living in the property',
                    validationMessage: 'Complete all required fields for at least one person'
                  },

                  {
                    key: 'peopleLivingWithYou.hasDependentChildren',
                    title: 'Do you have dependent children that live with you?',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_RFCA/templates/dependentChildrenDescription.html'),
                    condition: 'model.person.details.currentlyInCare !== true'
                  },

                  {
                    key: 'peopleLivingWithYou.hasDependentChildren',
                    title: 'Do you have dependent children living in your property?',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_RFCA/templates/dependentChildrenDescription.html'),
                    condition: 'model.person.details.currentlyInCare === true'

                  },

                  {
                    key: 'peopleLivingWithYou.dependentChildren',
                    condition: 'model.peopleLivingWithYou.hasDependentChildren',
                    add: "Add Person",
                    title: 'Details of dependent children living with you',
                    validationMessage: 'Complete all required fields for at least one child'
                  },

                  {
                    key: 'person.property.hasPreviouslyOwnedProperty',
                    condition: 'model.person.property.ownership === "rentedPrivately" || model.person.property.ownership === "ownedByFamilyMember" || model.person.property.ownership === "livingWithFamilyMember" || model.person.property.ownership === "councilTenant" || model.person.property.ownership === "other"'
                  },
                  {
                    key: 'person.property.propertyPreviouslyOwned',
                    condition: 'model.person.property.hasPreviouslyOwnedProperty',
                    title: 'Property you previously owned'
                  },

                  'person.property.hasAdditionalProperties',
                  {
                    key: 'person.property.additionalProperties',
                    condition: 'model.person.property.hasAdditionalProperties',
                    add: "Add property",
                    title: 'We need to know details and value(s) of any property/properties, including land, owned and/or jointly owned by you other than your main home',
                    validationMessage: 'Enter details of at least one property'
                  }

                ]
              }
            ]
          },
          {
            type: 'section',
            condition: 'model.page === 4',
            page: 4,
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your income',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/LI_RFCA/templates/cfa_animation_04_income-intro.html'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Wages/Salary</h4>"
                      },
                      {
                        key: 'person.income.hasWage',
                        title: 'Are you currently employed?'
                      },
                      {
                        key: 'person.income.wages',
                        condition: 'model.person.income.hasWage',
                        add: "Add salary/wage",
                        title: 'Wages/Salary',
                        validationMessage: 'Enter details of at least one employer'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Your pensions</h4>"
                      },
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.retirementPensionReceived'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({
                        key: 'person.income.retirementPension',
                        htmlClass: 'invisible'
                      }, {
                        htmlClass: 'invisible'
                      }),
                      {
                        key: 'person.income.hasAnnuity',
                        title: 'Are you in receipt of a pension annuity?',
                        description: 'A pension annuity is a financial product that you can buy when you retire and it pays you a guaranteed income either for a fixed period or for the rest of your life. The amount you enter should be after any deductions and taxes have been taken off. If you aren\'t sure whether your pension income is an annuity or a regular private pension you should consult the paperwork from your provider(s)'
                      },
                      {
                        key: 'person.income.annuities',
                        condition: 'model.person.income.hasAnnuity',
                        add: "Add another",
                        title: 'Pension Annuities',
                        validationMessage: 'Enter details of at least one pension annuity'
                      },
                      {
                        key: 'person.income.hasPrivatePension',
                        title: 'Are you in receipt of private/works pension(s)?',
                        description: 'This is the amount you receive after deductions and taxes have been taken off. You will find this figure on your pension statement or cheque. If your pension is paid directly into a bank account, this figure will be shown on your bank statement.'
                      },
                      {
                        key: 'person.income.privatePensions',
                        condition: 'model.person.income.hasPrivatePension',
                        add: "Add another",
                        title: 'Private/Works Pensions',
                        validationMessage: 'Enter details of at least one private/works pension'
                      },
                      {
                        key: 'person.income.hasUnaccessedPension',
                        title: 'Do you have any pensions/pension pots/plans or annuities that are due to start in the next 12 months?'
                      },
                      {
                        key: 'person.income.unaccessedPensions',
                        condition: 'model.person.income.hasUnaccessedPension',
                        add: "Add another",
                        title: 'Future Pensions',
                        validationMessage: 'Enter details of at least one unaccessed pension'
                      },
                      {
                        key: 'person.income.hasDeferredPension',
                        title: 'Do you have any pensions/pension pots/plans or annuities that have been deferred?',
                        description: 'A pension deferral means that you have delayed claiming, or have stopped your pension, until a time that suits you. However, we still need to take this into account when calculating your care contribution'
                      },
                      {
                        key: 'person.income.deferredPensions',
                        condition: 'model.person.income.hasDeferredPension',
                        add: "Add another",
                        title: 'Deferred Pensions',
                        validationMessage: 'Enter details of at least one deferred pension'
                      },
                      {
                        key: 'person.details.transferHalfPensionToPartner',
                        title: 'Disregard half pension and transfer to partner?',
                        description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_CA/templates/transferHalfPensionToPartner.html'),
                        condition: conditions.toString('hasPartner')
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Your partner's income",
                    htmlClass: "col-md-6",
                    items: [
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Wages/Salary</h4>"
                      },
                      {
                        key: 'partner.income.hasWage',
                        title: 'Is your partner currently employed?'
                      },
                      {
                        key: 'partner.income.wages',
                        condition: 'model.partner.income.hasWage',
                        add: "Add salary/wage",
                        title: 'Wages/Salary',
                        validationMessage: 'Enter details of at least one employer'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Your partner's pensions</h4>"
                      },
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.retirementPensionReceived'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({
                        key: 'partner.income.retirementPension',
                        htmlClass: 'invisible'
                      }, {
                        htmlClass: 'invisible'
                      }),
                      {
                        key: 'partner.income.hasPrivatePension',
                        title: 'Is your partner in receipt of private/works pension(s)?',
                        description: "This is the amount your partner receives after deductions and taxes have been taken off. Your partner will find this figure on their pension statement or cheque. If your partner's pension is paid directly into their bank account, this figure will be shown on their bank statement."
                      },
                      {
                        key: 'partner.income.privatePensions',
                        condition: 'model.partner.income.hasPrivatePension',
                        add: "Add more",
                        title: 'Private/Works Pensions',
                        validationMessage: 'Enter details of at least one private/works pension'
                      }
                    ],
                    condition: conditions.toString('partnerDisclosesFinances')
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Universal Credit',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'person.income.hasUniversalCredit',
                        title: 'Are you in receipt of Universal Credit?',
                        description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_RFCA/templates/universalCreditGuidance.html'),
                        condition: '!model.partner.income.hasUniversalCredit'
                      },
                      fieldDefinitionService.boolean.noRequiredForm({
                        key: 'person.income.hasUniversalCredit',
                        title: 'Are you in receipt of Universal Credit?',
                        description: 'Universal Credit can be claimed either by you or your partner',
                        condition: 'model.partner.income.hasUniversalCredit'
                      }),
                      {
                        key: 'person.income.universalCreditBreakdown',
                        notitle: true,
                        title: 'Universal Credit Allowances',
                        condition: 'model.person.income.hasUniversalCredit'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Partner's Universal Credit",
                    htmlClass: "col-md-6",
                    condition: conditions.toString('partnerDisclosesFinances'),
                    items: [
                      {
                        key: 'partner.income.hasUniversalCredit',
                        title: 'Is your partner in receipt of Universal Credit?',
                        description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_RFCA/templates/universalCreditGuidance.html'),
                        condition: '!model.person.income.hasUniversalCredit'
                      },
                      fieldDefinitionService.boolean.noRequiredForm({
                        key: 'partner.income.hasUniversalCredit',
                        title: 'Is your partner in receipt of Universal Credit?',
                        description: 'Universal Credit can be claimed either by you or your partner',
                        condition: 'model.person.income.hasUniversalCredit'
                      }),
                      {
                        key: 'partner.income.universalCreditBreakdown',
                        notitle: true,
                        title: 'Universal Credit Allowances',
                        condition: 'model.partner.income.hasUniversalCredit'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your benefits',
                    htmlClass: "col-md-6",
                    items: [
                      fieldDefinitionService.groups.paymentFormField({
                        key: 'person.income.attendanceAllowance',
                        condition: conditions.toString('person.income.attendanceAllowance')
                      }),
                      fieldDefinitionService.groups.paymentFormField({
                        key: 'person.income.dlaCare',
                        condition: conditions.toString('person.income.dlaCare')
                      }),
                      fieldDefinitionService.groups.paymentFormField({
                        key: 'person.income.pipDailyLiving',
                        condition: conditions.toString('person.income.pipDailyLiving')
                      }),

                      fieldDefinitionService.groups.paymentFormField({key: 'person.income.mobilityAllowance'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.incapacityBenefit'}),


                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.employmentSupport',
                        condition: '!model.partner.income.employmentSupportIsJoint'
                      }, {}, {
                        condition: conditions.toString('hasPartner')
                      }),

                      meansTestedBenefitPremia(true, 'employmentSupport', 'Employment Support Allowance'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.jobSeekersAllowance',
                        condition: '!model.partner.income.jobSeekersAllowanceIsJoint'
                      }, {}, {
                        condition: conditions.toString('hasPartner')
                      }),

                      meansTestedBenefitPremia(true, 'jobSeekersAllowance', 'Job Seeker\'s  Allowance'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.incomeSupportPayment',
                        condition: '!model.partner.income.incomeSupportPaymentIsJoint'
                      }, {}, {
                        condition: conditions.toString('hasPartner')
                      }),

                      meansTestedBenefitPremia(true, 'incomeSupportPayment', 'Income Support'),

                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.maternityAllowance'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.exceptionalSevereDisablementAllowance'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.severeDisablementAllowance'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.workingTaxCredit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.childTaxCredit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.industrialInjuriesBenefit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.warPension'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.warWidowsPension'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.armedForcesPayment'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.warSpecialPayment'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.carersAllowance'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.childBenefit'}),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.housingBenefit',
                        condition: "model.person.property.ownership !== 'ownedByYou'",
                      }, {}, {
                        condition: conditions.toString('hasPartner')
                      }),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.pensionGuaranteeCredit',
                        condition: '!model.partner.income.pensionGuaranteeCreditIsJoint',
                        description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_RFCA/templates/pensionCredit.html')
                      }, {}, {
                        condition: conditions.toString('hasPartner')
                      }),

                      meansTestedBenefitPremia(true, 'pensionGuaranteeCredit', 'Pension Guarantee Credit'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'person.income.pensionSavingsCredit',
                        condition: '!model.partner.income.pensionSavingsCreditIsJoint'
                      }, {}, {
                        condition: conditions.toString('hasPartner')
                      }),


                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Your partner's benefits",
                    htmlClass: "col-md-6",
                    items: [
                      fieldDefinitionService.groups.paymentFormField({
                        key: 'partner.income.attendanceAllowance',
                        condition: conditions.toString('partner.income.attendanceAllowance')
                      }),
                      fieldDefinitionService.groups.paymentFormField({
                        key: 'partner.income.dlaCare',
                        condition: conditions.toString('partner.income.dlaCare')
                      }),
                      fieldDefinitionService.groups.paymentFormField({
                        key: 'partner.income.pipDailyLiving',
                        condition: conditions.toString('partner.income.pipDailyLiving')
                      }),

                      fieldDefinitionService.groups.paymentFormField({key: 'partner.income.mobilityAllowance'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.incapacityBenefit'}),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.employmentSupport',
                        condition: '!model.person.income.employmentSupportIsJoint'
                      }, {}, {
                        condition: conditions.toString('hasPartner')
                      }),

                      meansTestedBenefitPremia(false, 'employmentSupport', 'Employment Support Allowance'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.jobSeekersAllowance',
                        condition: '!model.person.income.jobSeekersAllowanceIsJoint'
                      }, {}, {
                        condition: conditions.toString('hasPartner')
                      }),

                      meansTestedBenefitPremia(false, 'jobSeekersAllowance', 'Job Seeker\'s  Allowance'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.incomeSupportPayment',
                        condition: '!model.person.income.incomeSupportPaymentIsJoint'
                      }, {}, {
                        condition: conditions.toString('hasPartner')
                      }),

                      meansTestedBenefitPremia(false, 'incomeSupportPayment', 'Income Support'),

                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.maternityAllowance'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.exceptionalSevereDisablementAllowance'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.severeDisablementAllowance'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.workingTaxCredit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.childTaxCredit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.industrialInjuriesBenefit'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.warPension'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.warWidowsPension'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.armedForcesPayment'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.warSpecialPayment'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.carersAllowance'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.childBenefit'}),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.housingBenefit',
                        condition: "model.person.property.ownership !== 'ownedByYou'",
                      }, {}, {
                        condition: conditions.toString('hasPartner')
                      }),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.pensionGuaranteeCredit',
                        condition: '!model.person.income.pensionGuaranteeCreditIsJoint',
                        description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_RFCA/templates/pensionCredit.html')
                      }, {}, {
                        condition: conditions.toString('hasPartner')
                      }),

                      meansTestedBenefitPremia(false, 'pensionGuaranteeCredit', 'Pension Guarantee Credit'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.pensionSavingsCredit',
                        condition: '!model.person.income.pensionSavingsCreditIsJoint'
                      }, {}, {
                        condition: conditions.toString('hasPartner')
                      }),


                    ],
                    condition: conditions.toString('partnerDisclosesFinances')
                  }
                ]
              },

              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your other income',
                    htmlClass: "col-md-6",
                    items: [
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.rentalIncome'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.annuitiesIncome'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.trustFunds'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.maintenance'}),
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'person.income.otherIncome'}),
                      'person.income.hasOtherBenefit',
                      {
                        key: 'person.income.otherBenefit',
                        condition: 'model.person.income.hasOtherBenefit'
                      },
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Your partner's other income",
                    htmlClass: "col-md-6",
                    items: [
                      fieldDefinitionService.groups.periodicPaymentFormFields({key: 'partner.income.otherIncome'}),
                      'partner.income.hasOtherBenefit',
                      {
                        key: 'partner.income.otherBenefit',
                        condition: 'model.partner.income.hasOtherBenefit'
                      },
                    ],
                    condition: conditions.toString('partnerDisclosesFinances')
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Personal Injury Claim',
                    htmlClass: "col-md-12",
                    items: [
                      {
                        key: 'person.personalInjury.hasReceivedCompensation',
                        title: 'Have you received compensation for any form of personal injury?',
                      },
                      {
                        key: 'person.personalInjury.details',
                        condition: 'model.person.personalInjury.hasReceivedCompensation',
                        title: 'Please give more details about this award and where the monies are held. You will also need to provide a copy of the award.'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Private Health Insurance/Hospital Fund Scheme',
                    htmlClass: "col-md-12",
                    items: [
                      {
                        key: 'person.healthInsurance.hasInsurance',
                        title: 'Do you have Private Health Insurance which covers you for Home Care or Residential Care?',
                        description: 'If you have Private Insurance or hospital fund scheme you may be asked to contribute the full amount of any payment you receive towards the service provided.'
                      },
                      {
                        key: 'person.healthInsurance.details',
                        condition: 'model.person.healthInsurance.hasInsurance',
                        notitle: true
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'section',
            condition: 'model.page === 5',
            page: 5,
            items: [
              'person.property.heatingType',
              {
                key: 'person.property.otherHeatingType',
                condition: 'model.person.property.heatingType === "other"'
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your payments and outgoings',
                    htmlClass: "col-md-6",
                    items: [
                      {
                      type: 'template',
                      htmlClass: 'divTitle',
                      templateUrl: 'modules/forms/scripts/services/forms/LI_RFCA/templates/cfa_animation_05_outgoings-intro.html'
                      },
                      fieldDefinitionService.groups.periodicPaymentFormFields({
                        key: 'person.expenses.compulsoryCourtPayments',
                        description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_CA/templates/courtOrderedPayments.html')
                      }),
                      fieldDefinitionService.groups.paymentFormField({
                        key: 'person.expenses.councilTax',
                      }),
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/LI_CA/templates/annualCouncilTaxDescription.html'
                      },
                      'person.expenses.howCouncilTaxIsPaid',
                      fieldDefinitionService.groups.periodicPaymentFormFields({
                        key: 'person.expenses.rent',
                        description: "<p>Enter the total amount of rent, as shown on your tenancy agreement or most recent rent letter.</p><p><strong>Please note:</strong> If you are paying rent to a family member that you live with and there is no formal rental agreement in place, this amount will be disregarded from the calculation so you should <strong>not</strong> enter it here.</p>"
                      }),
                      fieldDefinitionService.groups.periodicPaymentFormFields({
                        key: 'person.expenses.mortgage',
                        condition: conditions.toString('person.expenses.mortgage')
                      }),
                      fieldDefinitionService.groups.periodicPaymentFormFields({
                        key: 'person.expenses.mortgageInsurance',
                        condition: conditions.toString('person.expenses.mortgage'),
                        description: "For any endowment/life insurance policies then these have to be directly linked to your mortgage (i.e. it can't be stand alone life insurance). We may ask you for evidence of this."
                      }),
                      fieldDefinitionService.groups.periodicPaymentFormFields({
                        key: 'person.expenses.homeInsurance',
                        description: "<p>If you have a combined buildings and contents insurance policy, enter the total amount you pay.</p><p><strong>Please note:</strong> Building insurance details are only required on the property that you currently reside in (or for your former home if you still own it but are now residing in a care home setting). Insurance details are not required on rented property.</p>"
                      }),
                      {
                        key: 'person.expenses.isHomeInsuranceCombined',
                        condition: 'model.person.expenses.homeInsurance'
                      },
                      fieldDefinitionService.groups.periodicPaymentFormFields({
                        key: 'person.expenses.groundRent',
                      }),

                      fieldDefinitionService.groups.periodicPaymentFormFields({
                        key: 'person.expenses.waterRates',
                      }),

                      fieldDefinitionService.groups.periodicPaymentFormFields({
                        key: 'person.expenses.gas',
                      }),

                      fieldDefinitionService.groups.periodicPaymentFormFields({
                        key: 'person.expenses.electric',
                      }),

                      fieldDefinitionService.groups.periodicPaymentFormFields({
                        key: 'person.expenses.lifeline',
                      })

                    ]
                  },
                  {
                    type: "fieldset",
                    condition: conditions.toString('partnerDisclosesFinances'),
                    title: "Your partner's payments and outgoings",
                    htmlClass: "col-md-6",
                    items: [
                      fieldDefinitionService.groups.periodicPaymentFormFields({
                        key: 'partner.expenses.compulsoryCourtPayments',
                        description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_CA/templates/courtOrderedPayments.html')
                      })
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 6',
            page: 6,
            items: [
              {
                type: "fieldset",
                title: 'Capital - Transfers and Gifting',
                items: [
                  {
                    type: 'template',
                    htmlClass: 'divTitle',
                    templateUrl: 'modules/forms/scripts/services/forms/LI_RFCA/templates/cfa_animation_12_capital_transfers-gifting-intro.html'
                  },
                  'longTermResidents.deprivationOfAssets.hasDisposedOfAssets',
                  {
                    key: 'longTermResidents.deprivationOfAssets.disposedAssets',
                    title: false,
                    condition: 'model.longTermResidents.deprivationOfAssets.hasDisposedOfAssets',
                    validationMessage: 'Enter details of at least one asset'
                  },
                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/LI_RFCA/templates/transferredAssets.html",
                    condition: 'model.longTermResidents.deprivationOfAssets.hasDisposedOfAssets'
                  },
                  {
                    title: "Authority to obtain information",
                    key: 'longTermResidents.deprivationOfAssets.advisor',
                    condition: 'model.longTermResidents.deprivationOfAssets.hasDisposedOfAssets'
                  }
                ]
              },
              {
                type: "fieldset",
                title: 'Capital Assets',
                items: [
                  'longTermResidents.businessOwnership.hasBusinessInterests',
                  {
                    title: false,
                    key: 'longTermResidents.businessOwnership.business',
                    condition: 'model.longTermResidents.businessOwnership.hasBusinessInterests'
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 7',
            page: 7,
            title: 'Cost of care',
            items: costOfCareItems(),
          },
          {
            type: 'fieldset',
            condition: 'model.page === 8',
            page: 8,
            title: 'How to make your contribution for care',
            items: [
              {
                key: 'careAssessment.paymentMethod',
                description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/LI_RFCA/templates/paymentMethodDescription.html')
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 9',
            page: 9,
            title: "Filling in the form on someone else's behalf",
            items: [
              {
                type: 'template',
                htmlClass: 'divTitle',
                templateUrl: 'modules/forms/scripts/services/forms/LI_RFCA/templates/cfa_animation_07_advocate-intro.html'
              },
              'agent.isCompletedByAgent',
              {
                key: 'agent.reason',
                condition: 'model.agent.isCompletedByAgent'
              },
              {
                key: 'agent.details',
                condition: 'model.agent.isCompletedByAgent',
                title: 'Details of the person who filled the form'
              },
              {
                key: 'agent.applicantConfirmationDeclaration',
                validationMessage: 'You must agree to this declaration',
                condition: conditions.toString('agent.applicantConfirmationDeclaration')
              },
              {
                key: 'agent.agentDeclaration',
                validationMessage: 'You must agree to this declaration',
                condition: conditions.toString('agent.agentDeclaration')
              },
              {
                type: "fieldset",
                title: "Declaration and Undertaking",
                items: [
                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/LI_RFCA/templates/declaration.html"
                  },
                  {
                    key: 'declaration.isAccepted',
                    description: 'Please type your full name into the box.',
                    validationMessage: 'You must indicate that you agree to and understand the above declaration'
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 10',
            page: 10,
            title: "Letter of Authority",
            items: [
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/LI_RFCA/templates/letterOfAuthority.html"
              },
              'letterOfAuthority.isPersonNominated',
              {
                type: 'fieldset',
                title: 'Details of nominated person or agency',
                condition: 'model.letterOfAuthority.isPersonNominated',
                items: [
                  'letterOfAuthority.nominatedPersonDetails.name',
                  'letterOfAuthority.nominatedPersonDetails.phoneNumber',
                  'letterOfAuthority.nominatedPersonDetails.emailAddress',
                  'letterOfAuthority.nominatedPersonDetails.relationship'
                ]
              },
              {
                key: 'letterOfAuthority.permitDiscussion',
                validationMessage: 'You must agree to this declaration',
                condition: 'model.letterOfAuthority.isPersonNominated'
              },
              {
                key: 'letterOfAuthority.permitCorrespondence',
                condition: 'model.letterOfAuthority.isPersonNominated'
              },
              {
                type: 'fieldset',
                title: 'Address of nominated person or agency',
                condition: 'model.letterOfAuthority.isPersonNominated && model.letterOfAuthority.permitCorrespondence',
                items: [
                  'letterOfAuthority.nominatedPersonDetails.addressLine1',
                  'letterOfAuthority.nominatedPersonDetails.addressLine2',
                  'letterOfAuthority.nominatedPersonDetails.addressLine3',
                  'letterOfAuthority.nominatedPersonDetails.postcode'
                ]
              }
            ]
          },
          {
            type: "section",
            condition: "areFormNavigationButtonsVisible()",
            items: [
              {
                type: "actions",
                htmlClass: "formPaginationButtons",
                items: [
                  {
                    type: "button",
                    style: "btn-default",
                    title: "Previous",
                    onClick: "prev()"
                  },
                  {
                    type: "button",
                    style: "btn-primary",
                    title: "Next",
                    onClick: "next()"
                  }]
              }
            ]
          }
        ]);

        return formUI;
      }]);
