'use strict';

angular.module('kerp-forms.forms').factory(
  'HAM_FCA_CONDITIONS',
  [
    'FormConditions',

    function (
      FormConditions
    ) {


      return FormConditions.clone({
        conditions: {
          'person.property.value': function () {
            return this.lib.isAnyOf('person.property.ownership', ['ownedByYou', 'ownedJointly']);
          },
          'person.property.hasEquityRelease': function () {
            return this.lib.isAnyOf('person.property.ownership', ['ownedByYou', 'ownedJointly', 'ownedByPartner']);
          },
          'person.income.attendanceAllowance': function () {
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

            return !hasDlaCare && !hasPipDla;
          },

          'person.income.dlaCare': function () {
            var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
            var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

            return !hasAA && !hasPipDla;
          },

          'person.income.pipDailyLiving': function () {
            var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);

            return !hasAA && !hasDlaCare;
          },

          'person.income.receivesExtraCare': function() {
            var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails');
            var applyingForCaretype = this.lib.getModelValue('person.intro.applyingForCaretype');

            return (hasAA || hasDlaCare || hasPipDla) && provideFinancialDetails === 'agree' && applyingForCaretype !== undefined && applyingForCaretype !== 'R';
          },

          'partner.income.attendanceAllowance': function () {
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return !hasDlaCare && !hasPipDla;
          },

          'partner.income.dlaCare': function () {
            var hasAA = this.lib.isAnyOf('partner.income.attendanceAllowance', ['high', 'low']);
            var hasPipDla = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return !hasAA && !hasPipDla;
          },

          'partner.income.pipDailyLiving': function () {
            var hasAA = this.lib.isAnyOf('partner.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);

            return !hasAA && !hasDlaCare;
          },

          'partner.income.receivesExtraCare': function() {
            var hasAA = this.lib.isAnyOf('partner.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return (hasAA || hasDlaCare || hasPipDla);
          },

          'person.income.universalCredit': function () {
            var uc = this.lib.getModelValue('person.income.universalCredit');
            return uc > 0;
          },

          'showResidentialCostOfCare': function () {
            // KERP-6041
            // hides residential cost of care until further notice
            // replaces with holding template
            return false;
          },

          'alreadyInResidentialCare': function () {
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails');
            var applyingForCaretype = this.lib.getModelValue('person.intro.applyingForCaretype');
            var alreadyInPermanentCare = this.lib.getModelValue('person.details.alreadyInPermanentCare');

            return provideFinancialDetails && applyingForCaretype !== undefined && applyingForCaretype !== 'NR' && alreadyInPermanentCare === 'yes';
          },

          'notOnlyApplyingForResidentialCare': function () {
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails');
            var applyingForCaretype = this.lib.getModelValue('person.intro.applyingForCaretype');

            return provideFinancialDetails === 'agree' && applyingForCaretype !== undefined && applyingForCaretype !== 'R';
          },

          'notOnlyApplyingForNonResidentialCare': function () {
            var provideFinancialDetails = this.lib.getModelValue('person.intro.provideFinancialDetails');
            var applyingForCaretype = this.lib.getModelValue('person.intro.applyingForCaretype');

            return provideFinancialDetails === 'agree' && applyingForCaretype !== undefined && applyingForCaretype !== 'NR';
          },

          previousAddresses: function() {
            var dateMovedIn = this.lib.getModelValue('person.details.address.movedIn');
            var previousAddresses = this.lib.getModelValue('person.details.address.previousAddresses');

            const movedInDate = moment(dateMovedIn, 'DD/MM/YYYY');
            const threshold = moment().subtract(5, 'years');
            const dateDiff = movedInDate.diff(threshold, 'days');

            return (dateDiff > 0) || previousAddresses !== undefined;
          }
        }
      });
    }]);
