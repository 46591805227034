'use strict';

angular.module('kerp-forms.forms').service(
  'lincsCareAssessmentCalculator',

  [
    '$window',
    'sfSelect',
    'objectUtils',
    'calculationResultService',

    function (
      $window,
      sfSelect,
      objectUtils,
      calculationResultService
    ) {

      function saveCalcResults(careType, calcResults, model) {
        calculationResultService.saveCalcResults(careType, calcResults, model);

        var personGeneratedPensionData = objectUtils.getPropertyPath(calcResults, 'generated.person.statePensionBeforeAgeAddition');
        var partnerGeneratedPensionData = objectUtils.getPropertyPath(calcResults, 'generated.partner.statePensionBeforeAgeAddition');

        if (personGeneratedPensionData) {
          sfSelect('person.income.sources.retirementPension', model, personGeneratedPensionData.amount);
          sfSelect('person.income.sources.retirementPensionPeriod', model, personGeneratedPensionData.period);
        }

        if (partnerGeneratedPensionData) {
          sfSelect('partner.income.sources.retirementPension', model, partnerGeneratedPensionData.amount);
          sfSelect('partner.income.sources.retirementPensionPeriod', model, partnerGeneratedPensionData.period);
        }
      }

      var constants = $window.kerpCfa.lincs.constants;

      this.recalculate = function (dirtyModel, cleanModel, isShortForm) {

        var careType = constants.careTypes.nonResidential;
        var calcResults = this.calculate(cleanModel, isShortForm);

        saveCalcResults(careType, calcResults, dirtyModel);
        saveCalcResults(careType, calcResults, cleanModel);
      };

      this.calculate = function (fields, isShortForm, careType) {
        return $window.kerpCfa.lincs.calculator(fields, isShortForm, careType);
      };
    }
  ]
);
