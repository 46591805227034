'use strict';

/**
 * Makes an element behave like a form link, i.e. when clicked, navigate either to the form's details page (if one exists) or
 * the form itself (external form)
 *
 * Example usage
 * =============
 * <a form-link="form">Open Form</a>
 *
 * The form-link attribute value should be a form object (defined in forms.js).
 *
 * Alternative usage
 * =================
 * <a form-link form-type-code="FCA"></a>
 *
 * If the content of the element is empty, the form's name will be used as the content.
 */
angular.module('kerp-forms.forms').directive('formLink', ['$state', '$window', 'Forms', function ($state, $window, Forms) {

    return {
      restrict: 'A',
      link: function (scope, element, attrs) {

        var form = attrs.formTypeCode ? Forms.getForm(attrs.formTypeCode) : scope.$eval(attrs.formLink);

        // this directive could be used with elements which are not links, e.g. buttons
        if (element[0].tagName === 'A') {
          /* jshint ignore:start */
          element.attr('href', '#');
          /* jshint ignore:end */
        }

        if (!element.html().length) {
          element.text(form.name);
        }

        element.on("click", function (e) {
          if (form.has_details_page) {
            $state.go('main.forms.show', {formId: form.id});

          } else if (form.has_external_form) {
            $window.open(form.external_link, '_blank');
          }
        });
      }
    };
  }]
);
