'use strict';

function FormModelFactory() {

  function FormModel(model, token) {

    this.id = model.id;
    this.dateCreated = model.dateCreated;
    this.lastUpdated = model.lastUpdated;
    this.fillablePdfUrl = model.fillablePdfUrl;
    this.link = model.link;
    this.formTypeCode = model.formTypeCode;
    this.formName = model.formName;
    this.lastValidPage = model.lastValidPage;
    this.pageCount = model.pageCount;
    this.reminderCount = model.reminderCount;
    this.user = model.user;
    this.evidences = model.evidences;
    this.remoteSubmission = model.remoteSubmission;
    this.token = token;
    this.externalReference = model.externalReference;
    this.allPagesValidated = model.allPagesValidated;
    this.cloneId = model.cloneId;

    // @transient used in views
    this.isDeleteConfimationPending = false;
    this.isAbandoned = false;
    this.submitStatusToggled = false;

    this.dirtyFields = model.dirtyFields || {};
    this.submittedFields = model.submittedFields || null;
  }

  FormModel.fromApiResponse = function (response) {
    var instance = new FormModel(JSON.parse(angular.toJson(response)));
    if (typeof response.dirtyFields === 'string') {
      try {
        instance.dirtyFields = JSON.parse(response.dirtyFields);
      } catch (e) {
        instance.dirtyFields = {};
      }
    }
    if (typeof response.submittedFields === 'string') {
      try {
        instance.submittedFields = JSON.parse(response.submittedFields);
      } catch (e) {
        instance.submittedFields = null;
      }
    }

    return instance;
  };

  FormModel.prototype = {
    constructor: FormModel,
    toApiJson: function () {
      return {
        id: this.id,
        dateCreated: this.dateCreated,
        lastUpdated: this.lastUpdated,
        link: this.link,
        formTypeCode: this.formTypeCode,
        formName: this.formName,
        lastValidPage: this.lastValidPage,
        pageCount: this.pageCount,
        allPagesValidated: this.allPagesValidated,
        fillablePdfUrl: this.fillablePdfUrl,
        submitted: this.isSubmitted(),
        dirtyFields: angular.toJson(this.dirtyFields),
        submittedFields: this.submittedFields && angular.toJson(this.submittedFields)
      };
    },
    setSubmittedFields: function (fields) {
      if (angular.isObject(fields)) {
        this.submittedFields = fields;
      } else {
        this.submittedFields = null;
      }

      this.submitStatusToggled = true;
    },
    isSubmitted: function () {
      return !!this.submittedFields;
    },
    isClone: function () {
      return !!this.cloneId;
    },
    toString: function () {
      angular.toJson(this);
    }
  };

  return FormModel;
}

angular.module('kerp-forms.forms').factory('FormModel', FormModelFactory);
