angular.module('kerp-forms.forms')
  .directive(
    'schemaFieldCareAssessmentResult',
    [
      '$timeout',
      'Forms',
      'formModelService',
      'numberUtils',
      '$exceptionHandler',
      'formErrorService',
      'FormErrorType',
      '$rootScope',
      'calculationResultService',
      'objectUtils',

      function (
        $timeout,
        Forms,
        formModelService,
        numberUtils,
        $exceptionHandler,
        formErrorService,
        FormErrorType,
        $rootScope,
        calculationResultService,
        objectUtils
      ) {

        return {
          restrict: 'A',
          scope: true,
          link: function (scope, element, attrs) {

            function getCareTypeKey(replacementSuffix, joinKeyParts) {
              var keyArray = scope.form.key.slice(0, -1);
              keyArray.push(replacementSuffix);
              return joinKeyParts ? keyArray.join('.') : keyArray;
            }

            var careType = scope.form.careType;
            var formTypeCode = scope.form.formTypeCode;
            var careAssessmentCalculator = scope.form.calculator;

            // Infer whether it's a short/long form from the form type code.
            // The form type codes for all full forms should be "FCA" (Kirklees), "LI_FCA" (Lincolnshire), etc.
            scope.isLongForm = /^.*_?FCA$/.test(formTypeCode);
            var careAssessmentFormConfig = Forms.getForm(formTypeCode);

            // re-run the calculation if the actual cost field is changed, or it's visibility is toggled
            var actualCostField = 'model.' + getCareTypeKey('cost', true);
            var isActualCostKnownField = 'model.' + getCareTypeKey('isActualCostKnown', true);
            var formValid = true;

            scope.$on("$destroy", function () {
              $rootScope.$emit(`form-result-clear`);
            });


            scope.$watchGroup([actualCostField, isActualCostKnownField], function (newFieldValues) {

              $timeout(function () {
                var canonicalFormModel = formModelService.getSubmittableFormModelFields(careAssessmentFormConfig, scope.model);

                if (!formValid) {
                  $rootScope.$emit(`form-result-validation-error`);
                  return;
                }

                try {
                  var calcResults = careAssessmentCalculator.calculate(canonicalFormModel, !scope.isLongForm, careType);
                  console.log(careType, "care calculation results", JSON.stringify(calcResults, null, 2));

                  calculationResultService.saveCalcResults(careType, calcResults, scope.model);

                  // add results to this directive's scope, so it can be displayed by the template
                  scope.careTypeKey = 'app.care.' + careType;
                  scope.hasActualCost = newFieldValues[0] && newFieldValues[1];
                  scope.hasMaxContribution = numberUtils.isNumeric(calcResults.contributions.maxContribution);
                  scope.contributions = calcResults.contributions;
                  scope.careType = careType;
                  scope.intro = objectUtils.getPropertyPath(scope.model, 'person.intro');


                } catch (e) {

                  var o = Offline.check();
                  $timeout(function () {

                    var formErrorType = $rootScope.app.isOffline ? FormErrorType.OFFLINE_CALCULATION_ERROR : FormErrorType.CALCULATION_ERROR;
                    formErrorService.setErrorState(
                      'fatal',
                      formErrorType
                    );
                    $exceptionHandler(e, 'Calculation error', undefined, {
                      form_data: scope.model
                    });
                  }, 0);
                }

                // KERP-2352, KERP-2431 Adding a short delay resolves this issue, though I don't entirely understand why.
                // This issue could not be reproduced when the application is run locally, but always occurred when
                // running remotely (on www, www2, etc.). Therefore, if you find a solution for this issue that does not
                // require a delay, be sure to test with the app running remotely before declaring victory.
              }, 500);

              $rootScope.$on('renderSchemaFormPageError', function () {
                formValid = false;
              });

            });
          }
        };
      }]);
