'use strict';
import { form } from './form.fn';
angular.module('kerp-forms.forms')
  .factory(
    'WOL_FCA_FORM',
    [
      'FormUI',
      'wolverhamptonCareAssessmentCalculator',
      'htmlService',
      'fieldDefinitionService',
      'WOL_FCA_CONDITIONS',
      '$window',

      form
    ]);
