'use strict';

angular.module('kerp-forms.forms').factory(
  'LI_RFCA_CONDITIONS',
  [
    'FormConditions',
    'numberUtils',
    '$window',

    function (
      FormConditions,
      numberUtils,
      $window
    ) {

      var constants = $window.kerpCfa.getConstants('LI_RFCA');

      function anyJSAorISisJoint (ctx) {
        var suReceivesIS = ctx.lib.getModelValue('person.income.incomeSupportPayment');
        var suISIsJoint = ctx.lib.getModelValue('person.income.incomeSupportPaymentIsJoint');
        var partnerReceivesIS = ctx.lib.getModelValue('partner.income.incomeSupportPayment');
        var partnerISIsJoint = ctx.lib.getModelValue('partner.income.incomeSupportPaymentIsJoint');
        var suReceivesJSA = ctx.lib.getModelValue('person.income.jobSeekersAllowance');
        var suJSAIsJoint = ctx.lib.getModelValue('person.income.jobSeekersAllowanceIsJoint');
        var partnerReceivesJSA = ctx.lib.getModelValue('partner.income.jobSeekersAllowance');
        var partnerJSAIsJoint = ctx.lib.getModelValue('partner.income.jobSeekersAllowanceIsJoint');

        var suGetsJointIS = suReceivesIS && suISIsJoint;
        var suGetsJointSJA = suReceivesJSA && suJSAIsJoint;
        var partnerGetsJointIS = partnerReceivesIS && partnerISIsJoint;
        var partnerGetsJointSJA = partnerReceivesJSA && partnerJSAIsJoint;

        return suGetsJointIS || suGetsJointSJA || partnerGetsJointIS || partnerGetsJointSJA;
      }

      function pgcIsJoint (ctx) {
        var suReceivesPGC = ctx.lib.getModelValue('person.income.pensionGuaranteeCredit');
        var suPGCIsJoint = ctx.lib.getModelValue('person.income.pensionGuaranteeCreditIsJoint');
        var partnerReceivesPGC = ctx.lib.getModelValue('partner.income.pensionGuaranteeCredit');
        var partnerPGCIsJoint = ctx.lib.getModelValue('partner.income.pensionGuaranteeCreditIsJoint');

        var suGetsJointPGC = suReceivesPGC && suPGCIsJoint;
        var partnerGetsJointPGC = partnerReceivesPGC && partnerPGCIsJoint;

        return suGetsJointPGC || partnerGetsJointPGC;
      }

      function grossIncomeBelowDWPThreshold (careAssessment) {
        let gi = 0;
        if (careAssessment) {
          gi = ((careAssessment || {}).workings || {}).grossIncome || 0;
        }
        let threshold;
        try {
          threshold = constants.dwpMIGIncomeThreshold;
        } catch(e) {
          threshold = 0;
        }
        return gi < threshold;
      }

      function hasContribution(careAssessment) {
        let hasContribution = false;
        if (careAssessment) {
          hasContribution = numberUtils.isNumeric(careAssessment.maxContribution);
        }
        return hasContribution;
      }

      return FormConditions.clone({
        conditions: {
          'agent.applicantConfirmationDeclaration': function () {
            return this.lib.isAnyOf('agent.details.relationship', ['advocate', 'friend']);
          },
          'agent.agentDeclaration': function () {
            return this.lib.isAnyOf('agent.details.relationship',
                                    ['appointeeship', 'powerOfAttorney', 'lastingPowerOfAttorney', 'deputyship']);
          },
          'person.expenses.rent': function () {
            return !this.lib.isAnyOf('person.details.propertyTenancy', ['ownedByYou', 'ownedByPartner', 'ownedJointly']);
          },
          'person.expenses.mortgage': function () {
            return !this.lib.isAnyOf('person.details.propertyTenancy', ['councilTenant', 'rentedPrivately']);
          },
          'longTermResidents.property.stillOwnProperty': ['longTermResidents.property.haveOwnedProperty', function () {
            return this.lib.getModelValue('longTermResidents.property.haveOwnedProperty');
          }],

          'longTermResidents.property.isStillOccupied': ['longTermResidents.property.stillOwnProperty', function () {
            return this.lib.getModelValue('longTermResidents.property.stillOwnProperty');
          }],

          'longTermResidents.property.ownershipCeased.date': ['longTermResidents.property.stillOwnProperty', function () {
            return this.lib.getModelValue('longTermResidents.property.stillOwnProperty') === false;
          }],

          'longTermResidents.property.ownershipCeased.howDisposed': ['longTermResidents.property.stillOwnProperty', function () {
            return this.lib.getModelValue('longTermResidents.property.stillOwnProperty') === false;
          }],

          'longTermResidents.property.ownershipCeased.transferredTo': ['longTermResidents.property.ownershipCeased.howDisposed', function () {
            return this.lib.getModelValue('longTermResidents.property.ownershipCeased.howDisposed') === 'transferred';
          }],

          'longTermResidents.property.occupiers': ['longTermResidents.property.isStillOccupied', function () {
            return this.lib.getModelValue('longTermResidents.property.isStillOccupied');
          }],

          'longTermResidents.property.confirmPropertyNeedsSale': ['longTermResidents.property.stillOwnProperty', function () {
            return this.lib.getModelValue('longTermResidents.property.stillOwnProperty');
          }],

          'person.income.attendanceAllowance': function () {
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

            return !hasDlaCare && !hasPipDla;
          },

          'person.income.dlaCare': function () {
            var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
            var hasPipDla = this.lib.isAnyOf('person.income.pipDailyLiving', ['high', 'low']);

            return !hasAA && !hasPipDla;
          },

          'person.income.pipDailyLiving': function () {
            var hasAA = this.lib.isAnyOf('person.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('person.income.dlaCare', ['high', 'medium', 'low']);

            return !hasAA && !hasDlaCare;
          },

          'partner.income.attendanceAllowance': function () {
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);
            var hasPipDla = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return !hasDlaCare && !hasPipDla;
          },

          'partner.income.dlaCare': function () {
            var hasAA = this.lib.isAnyOf('partner.income.attendanceAllowance', ['high', 'low']);
            var hasPipDla = this.lib.isAnyOf('partner.income.pipDailyLiving', ['high', 'low']);

            return !hasAA && !hasPipDla;
          },

          'partner.income.pipDailyLiving': function () {
            var hasAA = this.lib.isAnyOf('partner.income.attendanceAllowance', ['high', 'low']);
            var hasDlaCare = this.lib.isAnyOf('partner.income.dlaCare', ['high', 'medium', 'low']);

            return !hasAA && !hasDlaCare;
          },

          'showNonResidentialCostOfCare': function () {
            var currentCareIsSelfFunded = this.lib.getModelValue('person.details.currentCareIsSelfFunded');
            return !currentCareIsSelfFunded;
          },

          'showNonResidentialBreakdown': function () {
            var contribution = hasContribution(this.lib.getModelValue('careAssessment.nonResidential'));
            var currentCareIsSelfFunded = this.lib.getModelValue('person.details.currentCareIsSelfFunded');
            return contribution && !currentCareIsSelfFunded;
          },

          'showTempResidentialCostOfCare': function () {
            var currentCareIsSelfFunded = this.lib.getModelValue('person.details.currentCareIsSelfFunded');
            return !(anyJSAorISisJoint(this)) && !(currentCareIsSelfFunded);
          },

          'showTempResidentialBreakdown': function () {
            var contribution = hasContribution(this.lib.getModelValue('careAssessment.temporaryResidential'));
            var currentCareIsSelfFunded = this.lib.getModelValue('person.details.currentCareIsSelfFunded');
            return contribution && !(anyJSAorISisJoint(this)) && !currentCareIsSelfFunded;
          },

          'showPerm1ResidentialCostOfCare': function () {
            var currentCareIsSelfFunded = this.lib.getModelValue('person.details.currentCareIsSelfFunded');
            return !(anyJSAorISisJoint(this)) && !(currentCareIsSelfFunded);
          },

          'showPerm1ResidentialBreakdown': function () {
            var contribution = hasContribution(this.lib.getModelValue('careAssessment.permWeek1Residential'));
            var hasJointJSAorIS = anyJSAorISisJoint(this);
            var giBelowThreshold = grossIncomeBelowDWPThreshold(this.lib.getModelValue('careAssessment.permWeek1Residential'));
            var jointPGCDWPApplies = pgcIsJoint(this) && giBelowThreshold;
            var currentCareIsSelfFunded = this.lib.getModelValue('person.details.currentCareIsSelfFunded');

            return contribution && !(hasJointJSAorIS) && !(jointPGCDWPApplies) && !(currentCareIsSelfFunded);
          },

          'showPerm13ResidentialCostOfCare': function () {
            var currentCareIsSelfFunded = this.lib.getModelValue('person.details.currentCareIsSelfFunded');
            return !(anyJSAorISisJoint(this) || currentCareIsSelfFunded);
          },

          'showPerm13ResidentialBreakdown': function () {
            var contribution = hasContribution(this.lib.getModelValue('careAssessment.permWeek13Residential'));
            var currentCareIsSelfFunded = this.lib.getModelValue('person.details.currentCareIsSelfFunded');
            var hasJointJSAorIS = anyJSAorISisJoint(this);
            var giBelowThreshold = grossIncomeBelowDWPThreshold(this.lib.getModelValue('careAssessment.permWeek13Residential'));
            var jointPGCDWPApplies = pgcIsJoint(this) && giBelowThreshold;

            return contribution && (!(hasJointJSAorIS || currentCareIsSelfFunded)) && !(jointPGCDWPApplies);
          },

          'jointJSAorISInReceipt': function () {
            return anyJSAorISisJoint(this);
          },

          hasPartner: function() {
            var currentlyInCare = this.lib.getModelValue('person.details.currentlyInCare');
            var hasPartner = this.lib.getModelValue('person.intro.hasPartner');

            return !currentlyInCare && hasPartner;
          },

          partnerDisclosesFinances: function () {
            var currentlyInCare = this.lib.getModelValue('person.details.currentlyInCare');
            var hasPartner = this.lib.getModelValue('person.intro.hasPartner');
            var partnerDisclosesFinances = this.lib.getModelValue('partner.intro.discloseFinances');

            return !currentlyInCare && hasPartner && partnerDisclosesFinances;
          },

          propertyOwned: function() {
            var ownershipOfPreviousProperty = this.lib.getModelValue('person.property.ownershipOfPreviousProperty');
            var ownership = this.lib.getModelValue('person.property.ownership');
            var currentlyInCare = this.lib.getModelValue('person.details.currentlyInCare');

            return (currentlyInCare && ownershipOfPreviousProperty === "ownedOrPartOwned") || (!currentlyInCare && ownership === "ownedByYou");
          },

          propertyRented: function() {
            var ownershipOfPreviousProperty = this.lib.getModelValue('person.property.ownershipOfPreviousProperty');
            var ownership = this.lib.getModelValue('person.property.ownership');
            var currentlyInCare = this.lib.getModelValue('person.details.currentlyInCare');

            return (currentlyInCare && ownershipOfPreviousProperty === "rented") || (!currentlyInCare && ownership === "rentedPrivately");
          }
        }
      });
    }]);
